// src/components/SiderTabsMobile.js
import React, { useContext } from 'react';
import { Tabs, Affix } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  SettingOutlined,
  LineChartOutlined,
  HistoryOutlined,
  ApiOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import ApiIntegrationsContext from '../ApiIntegrationsContext';

const { TabPane } = Tabs;

function SiderTabsMobile() {
  const location = useLocation();
  const activeKey = location.pathname.split('/').pop() || 'chatbot';
  const { apiIntegrations } = useContext(ApiIntegrationsContext);

  return (
    <Affix offsetTop={64}>
      <Tabs
        className="sider-tabs-mobile"
        defaultActiveKey="chatbot"
        activeKey={activeKey}
        centered
        style={{ background: '#f5f5f5' }}
      >
        {apiIntegrations['Chatbot']?.isEnabled && ( // check if the feature is enabled
          <TabPane
            tab={
              <Link to="/my-tools/chatbot">
                <HomeOutlined />
                Chatbot
              </Link>
            }
            key="chatbot"
          />
        )}
        {apiIntegrations['Generative Agent']?.isEnabled && ( // check if the feature is enabled
          <TabPane
            tab={
              <Link to="/my-tools/cover-letter">
                <FileTextOutlined />
                Generative Agent
              </Link>
            }
            key="cover-letter"
          />
        )}
        {apiIntegrations['Web Scraper']?.isEnabled && ( // check if the feature is enabled
          <TabPane
            tab={
              <Link to="/my-tools/scraper">
                <FileTextOutlined />
                Scraper
              </Link>
            }
            key="scraper"
          />
        )}
        <TabPane
          tab={
            <Link to="/my-tools/settings">
              <SettingOutlined />
              Settings
            </Link>
          }
          key="settings"
        />
        <TabPane
          tab={
            <Link to="/my-tools/apps">
              <ApiOutlined />
              Apps
            </Link>
          }
          key="apps"
        />
        <TabPane
          tab={
            <Link to="/my-tools/analytics">
              <LineChartOutlined />
              Analytics
            </Link>
          }
          key="analytics"
        />
      </Tabs>
    </Affix>
  );
}

export default SiderTabsMobile;
