// src/components/PageHeader.js
import React, { useState } from 'react';
import { Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Modal from './Modal';
import { useMediaQuery } from 'react-responsive';

function PageHeader({
  title,
  description,
  showButton,
  buttonText,
  modalContent,
  buttonIcon,
  iconPosition,
  buttonType,
  buttonStyle,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => setIsModalVisible(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0',
        // borderBottom: '1px solid lightgrey',
        paddingBottom: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h1>{title}</h1>
        {showButton && (
          <Button
            type={buttonType}
            block
            style={{
              ...buttonStyle,
              width: isSmallScreen ? 'min-content !important' : 'auto',
            }}
            onClick={handleOpenModal}
            icon={isSmallScreen ? buttonIcon : null}
          >
            {!isSmallScreen && iconPosition === 'left' && buttonIcon}
            {!isSmallScreen && buttonText}
            {!isSmallScreen && iconPosition === 'right' && buttonIcon}
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: 'max-content',
        }}
      >
        <Typography style={{ maxWidth: '75ch' }}>{description}</Typography>
      </div>
      <Modal isVisible={isModalVisible} handleClose={handleCloseModal}>
        {modalContent}
      </Modal>
    </div>
  );
}

export default PageHeader;
