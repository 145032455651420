// src/components/ChatbotSiderDrawer.js
import React from 'react';
import { Drawer } from 'antd';
import ChatbotSider from './ChatbotSider';

function ChatbotSiderDrawer({ visible, onClose, ...props }) {
  return (
    <Drawer
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={200}
      bodyStyle={{ padding: '24px 8px 0 8px' }}
      headerStyle={{ padding: '12px 0 12px 0' }}
    >
      <ChatbotSider {...props} isDrawer={true} />
    </Drawer>
  );
}

export default ChatbotSiderDrawer;
