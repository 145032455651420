import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import AppHeader from './components/Header';
import AppFooter from './components/Footer';
import './App.css';
import BreadcrumbWrapper from './components/BreadcrumbWrapper';
import RoutesWithAuth from './RoutesWithAuth';
import { ApiIntegrationsProvider } from './ApiIntegrationsContext';

function App() {
  return (
    <Router>
      <ApiIntegrationsProvider>
        <Layout
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AppHeader />
          <Content />
          <FooterController />
        </Layout>
      </ApiIntegrationsProvider>
    </Router>
  );
}

function Content() {
  const location = useLocation();
  return (
    <div style={{ position: 'relative' }}>
      {location.pathname !== '/community-hub' && <BreadcrumbWrapper />}
      <RoutesWithAuth />
    </div>
  );
}

function FooterController() {
  const location = useLocation();
  const isNoFooterKind = location.pathname.includes('my-tools');
  return !isNoFooterKind ? <AppFooter /> : null;
}

export default App;
