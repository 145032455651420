import React, { useEffect, useState, useContext } from 'react';
import { Card, Row, Col, Layout, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { useMediaQuery } from 'react-responsive';
import { SettingOutlined } from '@ant-design/icons';
import AppSider from '../components/Sider';
import { AuthContext } from '../AuthContext';

const { Content } = Layout;

function CommunityHub() {
  const [blogPosts, setBlogPosts] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const importAll = (r) => r.keys().map((fileName) => r(fileName));
    const posts = importAll(require.context('./articles', false, /\.js$/));
    setBlogPosts(posts.map((postModule) => postModule.default));
  }, []);

  return (
    <Layout>
      {user && <AppSider />}
      <Layout style={{ flex: 1 }}>
        <Layout style={{ padding: '24px 24px 0 24px' }}>
          <PageHeader
            title="Welcome to Community Hub"
            description="Here our community members share their insights, experiences, and knowledge. Each post you see here is a contribution from a member of our community. Dive in, explore, and join the conversation!"
            showButton={true}
            buttonText="Apply as a writer"
            buttonType="primary"
            buttonStyle={{ backgroundColor: '#6cb84a' }}
            iconPosition="left"
          />
        </Layout>
        <Layout style={{ padding: '0', height: '100%' }}>
          <Row gutter={[12, 24]} style={{ padding: '24px', maxHeight: '100%' }}>
            {blogPosts.map((post, index) => (
              <Col xs={24} sm={24} md={12} lg={6} key={post.id}>
                <Link to={`/community-hub/${post.id}`}>
                  <Card
                    hoverable
                    cover={
                      <img
                        alt={post.title}
                        src={post.coverImage}
                        style={{ height: '180px', objectFit: 'cover' }}
                      />
                    }
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 'max-content',
                          }}
                        >
                          <Avatar size="small" src={post.authorAvatar} />
                          <p
                            style={{
                              fontSize: '12px',
                              color: 'grey',
                              margin: 0,
                            }}
                          >
                            {post.author}
                          </p>
                        </div>
                        <p
                          style={{ fontSize: '10px', color: 'grey', margin: 0 }}
                        >
                          {post.date}
                        </p>
                      </div>
                      <Card.Meta
                        title={
                          <p
                            style={{
                              WebkitLineClamp: '1',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              margin: 0,
                            }}
                          >
                            {post.title}
                          </p>
                        }
                        description={
                          <p
                            style={{
                              display: '-webkit-box',
                              WebkitLineClamp: '3',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              margin: '8px 0 24px 0',
                            }}
                          >
                            {post.summary}
                          </p>
                        }
                      />
                      <Link style={{ alignSelf: 'flex-end' }}>Read More</Link>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default CommunityHub;
