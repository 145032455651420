import React from 'react';
import { Input } from 'antd';

function PersonalityInput({ value, onChange }) {
  return (
    <Input
      value={value}
      onChange={onChange}
      placeholder="Enter personality traits"
    />
  );
}

export default PersonalityInput;
