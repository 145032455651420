// src/components/Header.js
import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Menu, Layout, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import UserDropdown from './UserDropdown';
import MobileDrawer from './MobileDrawer'; // Import MobileDrawer

const { Header } = Layout;

function AppHeader() {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const isNav1Active = location.pathname.startsWith('/my-tools');
  const isNav2Active = location.pathname.startsWith('/community-hub');
  const isNav3Active = location.pathname.startsWith('/about');
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        width: '100%',
        padding: '0 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: isMobile ? '0px 0px 0 16px' : '0px 20px 0 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isMobile ? (
          <MobileDrawer /> // Show MobileDrawer on small screens
        ) : (
          <>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={
                isNav1Active
                  ? ['1']
                  : isNav2Active
                  ? ['2']
                  : isNav3Active
                  ? ['3']
                  : []
              }
            >
              <div
                className="logo"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '24px',
                }}
              >
                <a href="/" style={{ height: '48px' }}>
                  <img src="/Logo.png" alt="Logo" />
                </a>
              </div>
              <Menu.Item key="1">
                <Link
                  to="/my-tools"
                  onClick={() => sessionStorage.setItem('fromNav1', 'true')}
                >
                  My Tools
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/community-hub">Community Hub</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/about">About</Link>
              </Menu.Item>
            </Menu>
            <div>
              {currentUser ? (
                <UserDropdown user={currentUser} />
              ) : (
                <Button type="link" onClick={() => navigate('/login')}>
                  Log in
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Header>
  );
}

export default AppHeader;
