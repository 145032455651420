import React from 'react';
import { Button, Row, Col, Card, Layout, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { Title, Paragraph } = Typography;

const Hero = ({ title, description, image, actions }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Layout
      style={{
        borderRadius: 'default',
        backgroundColor: 'white',
        height: 'max-content',
        borderRadius: '8px',
        padding: isSmallScreen ? '16px 16px' : '24px 24px',
      }}
    >
      <Row style={{ display: 'flex', height: '100%' }}>
        <Col xs={24} md={12} style={{ display: 'flex', height: '100%' }}>
          <img
            alt={title}
            src={image}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Col>
        <Col xs={24} md={12}>
          <Typography.Title level={2}>{title}</Typography.Title>
          <Paragraph>{description}</Paragraph>
          <Row gutter={16} direction={isSmallScreen ? 'column' : 'row'}>
            {actions &&
              actions.map((action, index) => (
                <Col key={index}>
                  <Button type={action.type} onClick={action.onClick}>
                    {action.label}
                  </Button>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default Hero;
