import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

function JobAdInput({ value, onChange }) {
  return (
    <TextArea
      value={value}
      onChange={onChange}
      placeholder="Job description or company description"
      style={{ minHeight: '180px' }}
    />
  );
}

export default JobAdInput;
