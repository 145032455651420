// src/pages/LoginPage.js
import React, { useState } from 'react';
import { auth } from '../firebase/firebase';
import { Form, Input, Button, Checkbox, Alert, Spin, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/ContentWrapper';
import { signInWithEmailAndPassword } from 'firebase/auth';

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const onFinish = (values) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        setLoading(false);
        navigate('/my-tools/chatbot');
      })
      .catch((error) => {
        setLoading(false);
        setLoginError('Failed to login. Try again.');
        console.error('Error logging in', error);
      });
  };

  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '48px',
          width: '100%',
          margin: '0px auto',
        }}
      >
        <Typography.Title
          level={1}
          style={{ textAlign: 'center', margin: '24px 0 48px' }}
        >
          Login
        </Typography.Title>
        <div style={{ width: '100%' }}>
          {location.state?.fromMainFeature && (
            <Alert
              message="You need to login to start using the web tools"
              type="warning"
              style={{ marginBottom: '24px' }}
            />
          )}
          {loginError && (
            <Alert
              message={loginError}
              style={{ marginBottom: '24px' }}
              type="error"
            />
          )}
          {loading && (
            <Alert
              message="Redirecting to the platform"
              type="success"
              icon={<Spin />}
              style={{ marginBottom: '24px' }}
            />
          )}
        </div>
        <Form
          onFinish={onFinish}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 0, span: 16 }}
          >
            <Checkbox size="large" style={{ paddingBottom: '12px' }}>
              Remember me
            </Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Log in
          </Button>
          <p style={{ margin: '48px 0', textAlign: 'center' }}>
            Don't have an account? <Link to="/register">Register</Link>
          </p>
        </Form>
      </div>
    </ContentWrapper>
  );
}

export default LoginPage;
