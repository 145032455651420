import axios from 'axios';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase/firebase';
import ApiIntegrationsContext from './ApiIntegrationsContext';

export async function getRelevantKeywords(
  template,
  jobAd,
  prompt,
  apiIntegrations,
) {
  // Only make the request if the OpenAI integration is active
  if (apiIntegrations['AdvancedChatbot']) {
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content:
                'You are a helpful assistant. Generate up to 10 keywords based on the following user input in template, jobAd and prompt. These keywords will be used for indexing and filtering content in a url So be precise with your keywords that ensure consistency.',
            },
            ...(template ? [{ role: 'user', content: template }] : []),
            ...(jobAd ? [{ role: 'user', content: jobAd }] : []),
            ...(prompt ? [{ role: 'user', content: prompt }] : []),
          ],
        },
        { headers },
      );

      // Assume the response contains two keywords separated by a comma
      const keywords = response.data.choices[0].message.content.split(', ');
      return keywords;
    } catch (error) {
      console.error('Failed to get relevant keywords:', error.response.data);
      throw error;
    }
  }
}

export async function getChatbotResponse(message) {
  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
    'Content-Type': 'application/json',
  };

  const model = ['gpt-3.5-turbo'].includes(localStorage.getItem('activeModel'))
    ? localStorage.getItem('activeModel')
    : 'gpt-3.5-turbo';

  const temperature = parseFloat(localStorage.getItem('temperature')) || 0.5;

  const customPrompt =
    localStorage.getItem('customPrompt') || 'You are a helpful assistant.';

  console.log(`Model: ${model}, Temperature: ${temperature}`);
  console.log('Chatbot message:', message);
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model,
        temperature,
        messages: [
          { role: 'system', content: customPrompt },
          { role: 'user', content: message },
        ],
      },
      { headers },
    );

    console.log('Chatbot response:', response.data); // Add this line

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Failed to get chatbot response:', error.response.data);
    throw error;
  }
}

export async function getActiveModel() {
  const model = ['gpt-3.5-turbo'].includes(localStorage.getItem('activeModel'))
    ? localStorage.getItem('activeModel')
    : 'gpt-3.5-turbo';
  return model;
}

export async function getContentFromUrl(url, keywords) {
  const indexUrl = httpsCallable(functions, 'indexUrl');
  try {
    const response = await indexUrl({ url });
    console.log('Link content:', response.data.data); // Log linkContent

    // Split the content into sentences
    const sentences = response.data.data.split('. ');

    // Filter the sentences based on the keywords
    const relevantSentences = sentences.filter((sentence) =>
      keywords.some((keyword) =>
        sentence.toLowerCase().includes(keyword.toLowerCase()),
      ),
    );

    // Only return content if at least two keywords are found
    if (relevantSentences.length >= 1) {
      return relevantSentences.join('. ');
    } else {
      console.log('Less than one keywords found in the URL');
      return '';
    }
  } catch (error) {
    console.error('Failed to get content from URL:', error);
    throw error;
  }
}

export async function generateCoverLetter(
  template,
  jobAd,
  prompt,
  linkContent,
  file,
  personal,
  workflowOutput,
) {
  console.log('generateCoverLetter parameters:', {
    template,
    jobAd,
    prompt,
    linkContent,
    file,
    personal,
    workflowOutput,
  });
  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
    'Content-Type': 'application/json',
  };

  const messages = [
    {
      role: 'system',
      content:
        'You are a helpful assistant. You help users to prepare cover letters for job applications based on the provided input about the job they provided as well as a template of a cover letter, the workflowOutput you receive, personal details and their custom prompt.  Generate your responses in consideration of the these details provided and try to point out the information in the cover letter that seems important and can be valued by the company that the user is going to apply for a position. Also, add a comment at the bottom of your response that highlights key information about the company profile you retrieve from the workflowOutput that aligns well with the applicant profile you receive from the user.',
    },
    { role: 'user', content: template },
    { role: 'user', content: jobAd },
    { role: 'user', content: prompt },
    { role: 'user', content: linkContent },
    { role: 'user', content: file },
    { role: 'user', content: personal },
  ];

  if (workflowOutput) {
    messages.push({ role: 'user', content: workflowOutput });
  }

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages,
      },
      { headers },
    );

    console.log(
      'Cover letter generated:',
      response.data.choices[0].message.content,
    ); // Log the generated cover letter
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Failed to generate cover letter:', error.response.data);
    throw error;
  }
}
