import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Layout, Badge } from 'antd';

function TermsOfService() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <Layout
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        height: '100vh'
      }}
    >
      <Layout
        style={{
          maxWidth: '80ch',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: isMobile ? '0' : '8px',
          padding: isMobile ? '16px' : '48px 48px 72px 48px',
          marginBottom: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '8px',
            alignItems: 'center',
            marginBottom: '1em',
          }}
        >
        </div>
        <h1
          style={{
            fontWeight: 'bold',
            marginBottom: '0.5em',
          }}
        >
          Terms of Service
        </h1>
        <p
          style={{
            lineHeight: '1.6',
            color: '#333',
          }}
        >
          Welcome to our Terms of Service. Your agreement to these terms is critically important to us. Our app is located at:
          [Your App Address]
          It is our policy to respect your rights and responsibilities while using our app.
          We have adopted these terms of service ("Terms of Service") to explain what is expected of you and what you can expect from us while using our app.
          These Terms of Service apply only to the use of our app and do not apply to any other interactions you may have with us.
          These Terms of Service, together with the Privacy Policy posted on our app, set forth the general rules and policies governing your use of our app.
          Depending on your activities when using our app, you may be required to agree to additional terms and conditions.
          We provide several different types of services for various purposes to meet your needs.
          While using our app, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data").
          Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, and other information.
          We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you.
          You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
          We also collect information that your browser sends whenever you visit our app or when you access the app by or through a mobile device ("Usage Data").
          This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our app that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          When you access the app with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
          We use cookies and similar tracking technologies to track the activity on our app and hold certain information.
          Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our app.
        </p>
      </Layout>
    </Layout>
  );
}

export default TermsOfService;
