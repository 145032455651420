// src/components/ChatbotSider.js
import React, { useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Layout, Menu, Affix, Button, Modal, Typography, Input } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MenuFoldOutlined,
  CommentOutlined,
  DownOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { Text } = Typography;

function ChatbotSider({
  threads,
  onThreadSelect,
  activeThread,
  onDeleteThread,
  onDeleteAll,
  onThreadTitleChange,
  onCreateThread,
  isDrawer,
}) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 900px) and (max-width: 1199px)',
  });
  const [editingThread, setEditingThread] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [collapsed, setCollapsed] = useState(isSmallScreen || isMediumScreen);
  const [deleteAll, setDeleteAll] = useState(false);

  useEffect(() => {
    console.log(threads);
  }, [threads]);

  const handleEditClick = (index) => {
    setEditingThread(index);
    setEditedTitle(threads[index].name);
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSubmit = (thread) => {
    onThreadTitleChange(thread, editedTitle, thread.messages || []);
    setEditingThread(null);
  };

  const handleDeleteClick = (e, thread) => {
    e.stopPropagation();
    setSelectedThread(thread);
    setIsModalVisible(true);
    setDeleteAll(false);
  };

  const handleDeleteAllClick = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
    setDeleteAll(true);
  };

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (deleteAll) {
          onDeleteAll();
        } else {
          onDeleteThread(selectedThread);
        }
        setIsModalVisible(false);
      }
    },
    [deleteAll, onDeleteAll, onDeleteThread, selectedThread],
  );

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('keydown', handleEnterPress);
    } else {
      document.removeEventListener('keydown', handleEnterPress);
    }
    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [isModalVisible, handleEnterPress]);

  return (
    <Affix offsetTop={64}>
      <Sider
        style={{
          height: '96vh',
          zIndex: 2,
          overflow: 'hidden',
          backgroundColor: '#fff',
        }}
        width={200}
        collapsed={isDrawer ? false : collapsed} // Always expanded when inside a Drawer
        collapsedWidth={48}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' || type === 'responsive') {
            setCollapsed(collapsed);
          }
        }}
      >
        {collapsed && !isDrawer ? (
          <div
            style={{
              height: '100%',
              borderRight: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop: '48px',
            }}
          >
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                width: '180px',
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                color: 'grey',
              }}
            >
              {collapsed ? 'View all threads' : 'Hide threads'}
              <DownOutlined />
            </Button>
          </div>
        ) : (
          <Menu
            mode="inline"
            selectedKeys={selectedThread ? [selectedThread.id] : []}
            onClick={({ key }) => onThreadSelect(key)}
            style={{ height: '100%', borderRight: 0, width: '100%' }} // Add width: '100%'
          >
            {!isDrawer && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  type="text"
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    padding: '0 12px',
                  }}
                >
                  <MenuFoldOutlined />
                </Button>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                padding: isDrawer ? '0' : '8px 12px 16px 12px',
                borderBottom: '1px solid #f0f0f0',
                margin: '0 0 4px 0',
              }}
            >
              <Typography.Title
                level={5}
                style={{
                  margin: '0 0 16px 0',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: 'darkgrey',
                }}
              >
                <CommentOutlined style={{ marginRight: '8px' }} />
                Chatbot
              </Typography.Title>
              <Button
                type="default"
                size="middle"
                icon={<PlusOutlined />}
                onClick={onCreateThread} // Ensure this is the only place where handleCreateNewClick is called
                style={{ width: '100%' }}
              >
                New thread
              </Button>
            </div>

            <Menu.ItemGroup key="g2" title="Saved threads" />
            {threads.map((thread, index) => (
              <Menu.Item
                key={thread.id}
                onClick={() => onThreadSelect(thread.id)}
                style={{
                  padding: '6px 0 6px 12px',
                  fontWeight: 400,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className={
                      activeThread && activeThread.id === thread.id
                        ? 'active'
                        : ''
                    }
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {editingThread === index ? (
                      <Input
                        value={editedTitle}
                        onChange={handleTitleChange}
                        onBlur={() => handleTitleSubmit(thread)}
                        onPressEnter={() => handleTitleSubmit(thread)}
                        autoFocus
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            adjustItems: 'center',
                          }}
                        >
                          <div className="ellipsis">{thread.name}</div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              type="text"
                              icon={
                                <EditOutlined
                                  style={{ fontSize: '14px', color: 'grey' }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick(index);
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            type="text"
                            icon={
                              <DeleteOutlined
                                style={{ fontSize: '14px', color: 'grey' }}
                              />
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(e, thread);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Menu.Item>
            ))}
            {threads.length > 1 && (
              <div style={{ padding: '10px' }}>
                <Button
                  type="link"
                  onClick={handleDeleteAllClick}
                  style={{ fontSize: '12px', width: '100%' }}
                >
                  Delete all
                </Button>
              </div>
            )}
          </Menu>
        )}
        <Modal
          title="Confirmation"
          visible={isModalVisible}
          onOk={(e) => {
            e.stopPropagation();
            if (deleteAll) {
              onDeleteAll();
            } else {
              onDeleteThread(selectedThread);
            }
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          okText={deleteAll ? 'Yes, delete all' : 'Yes, delete'}
          cancelText="Cancel"
          autoFocusButton="ok"
        >
          <p>
            Are you sure to delete {deleteAll ? 'all threads' : 'this thread'}?
            You won't be able to restore {deleteAll ? 'them' : 'it'} after
            confirming deletion.
          </p>
        </Modal>
      </Sider>
    </Affix>
  );
}

export default ChatbotSider;
