// src/pages/ChatbotPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiIntegrationsContext from '../ApiIntegrationsContext';
import {
  Layout,
  Button,
  Affix,
  Tabs,
  Slider,
  Input,
  Empty,
  Typography,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import ChatbotSider from '../components/ChatbotSider';
import MessagingScreen from '../components/MessagingScreen';
import UserInputField from '../components/UserInputField';
import { db } from '../firebase/firebase';
import { ref, onValue, set } from 'firebase/database';
import { AuthContext } from '../AuthContext';
import { getActiveModel } from '../openai';
import ChatbotSiderDrawer from '../components/ChatbotSiderDrawer';
import { CommentOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Tab } = Tabs;

function ChatbotPage() {
  const { apiIntegrations } = useContext(ApiIntegrationsContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!apiIntegrations['Chatbot']?.isEnabled) {
      navigate('/my-tools/apps');
    }
  }, [apiIntegrations, navigate]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const { currentUser } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [activeModel, setActiveModel] = useState('gpt-3.5-turbo');
  const [activeThread, setActiveThread] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [temperature, setTemperature] = useState(
    parseFloat(localStorage.getItem('temperature')) || 0.2,
  );

  const handleCustomPromptChange = (e) => {
    setCustomPrompt(e.target.value);
    localStorage.setItem('customPrompt', e.target.value);
  };

  const handleTemperatureChange = (value) => {
    setTemperature(value);
    localStorage.setItem('temperature', value);
  };

  useEffect(() => {
    setSelectedThread(threads[0]);
  }, [threads]);

  useEffect(() => {
    if (currentUser) {
      const dbRef = ref(db, 'threads/' + currentUser.uid);
      const unsubscribe = onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data object to an array
          const threads = Object.entries(data).map(([id, value]) => ({
            id,
            ...value,
            lastActive: value.lastActive ? new Date(value.lastActive) : null,
          }));
          setThreads(threads);
        } else {
          setThreads([]);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchActiveModel = async () => {
      const model = await getActiveModel();
      setActiveModel(model);
    };
    fetchActiveModel();
  }, []);

  const handleModelChange = (key) => {
    setActiveModel(key);
    localStorage.setItem('activeModel', key);
  };

  const handleSendMessage = async (userMessage, chatbotMessage) => {
    return new Promise((resolve) => {
      if (currentUser && selectedThread) {
        const now = Date.now(); // Get the current Unix epoch time
        setThreads((prevThreads) => {
          const newThreads = prevThreads.map((thread) => {
            if (thread === selectedThread) {
              return {
                ...thread,
                lastActive: now,
                messages: [
                  ...(thread.messages || []),
                  userMessage,
                  chatbotMessage
                    ? {
                        author: 'Chatbot',
                        content: chatbotMessage.content,
                        datetime: now,
                      }
                    : null,
                ].filter(Boolean),
              };
            } else {
              return thread;
            }
          });
          const dbRef = ref(db, 'threads/' + currentUser.uid);
          set(dbRef, newThreads);
          setIsLoading(false); // Set isLoading to false here
          return newThreads;
        });
      }
      resolve();
    });
  };

  const handleDeleteAllThreads = () => {
    threads.forEach((thread) => handleDeleteThread(thread));
  };

  const handleCreateThread = () => {
    if (currentUser) {
      const newThread = {
        name: 'New thread',
        messages: [
          {
            role: 'assistant',
            content: 'Hello! How can I assist you today?',
            createdAt: Date.now(),
          },
        ],
      };
      setThreads((prevThreads) => {
        const updatedThreads = [newThread, ...prevThreads];
        const dbRef = ref(db, 'threads/' + currentUser.uid);
        set(dbRef, updatedThreads);
        setSelectedThread(newThread);
        return updatedThreads;
      });
    }
  };
  const handleThreadSelect = (threadId) => {
    const thread = threads.find((t) => t.id === threadId);
    setActiveThread(thread);
    setSelectedThread(thread);
  };

  const handleDeleteThread = (threadToDelete) => {
    setThreads((prevThreads) => {
      const updatedThreads = prevThreads.filter(
        (thread) => thread !== threadToDelete,
      );
      const dbRef = ref(db, 'threads/' + currentUser.uid);
      set(dbRef, updatedThreads);
      if (selectedThread === threadToDelete) {
        setSelectedThread(updatedThreads[0]);
      }
      return updatedThreads;
    });
  };

  const handleThreadTitleChange = (thread, newTitle) => {
    setThreads((prevThreads) => {
      const updatedThreads = prevThreads.map((t) => {
        if (t === thread) {
          return { ...t, name: newTitle };
        } else {
          return t;
        }
      });
      const dbRef = ref(db, 'threads/' + currentUser.uid);
      set(dbRef, updatedThreads);
      return updatedThreads;
    });
  };
  return (
    <Affix offsetTop={64}>
      <Layout style={{ overflow: 'hidden' }}>
        <Layout style={{ height: isMobile ? '88vh' : '93vh' }}>
          <Layout
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            {isMobile ? (
              <>
                <div
                  style={{
                    padding: '0 12px',
                    width: '100%',
                    background: '#f5f5f5',
                    padding: '12px 8px',
                    zIndex: 2,
                  }}
                >
                  <Button
                    onClick={() => setIsDrawerVisible(true)}
                    style={{ width: '100%' }}
                  >
                    View all threads
                  </Button>
                  <ChatbotSiderDrawer
                    visible={isDrawerVisible}
                    onClose={() => setIsDrawerVisible(false)}
                    threads={threads}
                    onThreadSelect={handleThreadSelect}
                    activeThread={activeThread}
                    onDeleteThread={handleDeleteThread}
                    onDeleteAll={handleDeleteAllThreads}
                    onThreadTitleChange={handleThreadTitleChange}
                  />
                </div>
              </>
            ) : (
              <ChatbotSider
                threads={threads}
                onThreadSelect={handleThreadSelect}
                activeThread={activeThread}
                onDeleteThread={handleDeleteThread}
                onDeleteAll={handleDeleteAllThreads}
                onCreateThread={handleCreateThread}
                onThreadTitleChange={handleThreadTitleChange}
              />
            )}
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {threads.length === 0 ? (
                <div
                  style={{
                    padding: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'max-content',
                  }}
                >
                  <Empty
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    image={
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          marginBottom: 16,
                        }}
                      >
                        <CommentOutlined style={{ fontSize: '64px' }} />
                      </div>
                    }
                    description={
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Typography.Title
                          level={2}
                          style={{ marginTop: '12px' }}
                        >
                          Your Chatbot Comrade
                        </Typography.Title>
                        <Typography.Paragraph
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '0 0 20px',
                          }}
                        >
                          Chatbot Comrade is a tool that allows you to chat with
                          an AI. <br></br>You can use it to brainstorm ideas,
                          write stories, or just have a conversation. <br></br>
                          It's up to you!
                        </Typography.Paragraph>
                        <Button
                          type="primary"
                          size="large"
                          onClick={handleCreateThread}
                          style={{ width: isMobile ? '100%' : 'auto' }}
                        >
                          Start chatting
                        </Button>
                      </span>
                    }
                  />
                </div>
              ) : (
                <Layout
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      padding: '0',
                      boxShadow: '0 0 10px 4px #e4e4e4',
                      zIndex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column-reverse' : 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        padding: '8px 12px',
                      }}
                    >
                      {!isMobile && (
                        <p
                          style={{
                            fontSize: '10px',
                            textAlign: 'left',
                            lineHeight: '16px',
                          }}
                        >
                          Active Model: {activeModel} <br></br>
                          Temperature: {temperature}
                        </p>
                      )}
                      {!isMobile && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Tabs
                            defaultActiveKey="gpt-3.5-turbo"
                            onChange={handleModelChange}
                            style={{ height: '44px', textAlign: 'center' }}
                          >
                            <Tab key="gpt-3.5-turbo-0613" tab="GPT-3.5" />
                            <Tab key="gpt-4" tab="GPT-4" disabled />
                          </Tabs>
                        </div>
                      )}
                      <Button
                        type="default"
                        style={{
                          fontSize: '12px',
                        }}
                        onClick={() =>
                          setShowAdvancedControls(!showAdvancedControls)
                        }
                      >
                        {showAdvancedControls ? 'Hide' : 'Show'} Advanced
                        Controls
                      </Button>
                    </div>
                    {showAdvancedControls && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          gap: '8px',
                          justifyContent: 'center',
                          borderTop: '1px solid lightgrey',
                          padding: '12px',
                        }}
                      >
                        {isMobile && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Tabs
                                defaultActiveKey="gpt-3.5-turbo"
                                onChange={handleModelChange}
                                style={{ height: '44px', textAlign: 'center' }}
                              >
                                <Tab key="gpt-3.5-turbo-0613" tab="GPT-3.5" />
                                <Tab key="gpt-4" tab="GPT-4" disabled />
                              </Tabs>
                            </div>
                            <p
                              style={{
                                fontSize: '10px',
                                textAlign: 'left',
                                lineHeight: '16px',
                              }}
                            >
                              Active Model: {activeModel} <br></br>
                              Temperature: {temperature}
                            </p>
                          </>
                        )}
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <h5 style={{ margin: 0 }}>Temperature</h5>
                          <Slider
                            tooltip={{ open: true }}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={handleTemperatureChange}
                            value={temperature}
                            style={{ width: '240px' }}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <h5 style={{ margin: 0 }}>Custom prompts</h5>
                          <Input
                            placeholder="Enter custom prompt"
                            onChange={handleCustomPromptChange}
                            value={customPrompt}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <Content
                    className="site-layout-background"
                    style={{
                      padding: 24,
                      margin: 0,
                      flex: 1,
                      overflowY: 'scroll', // Make the content container scrollable
                    }}
                  >
                    <MessagingScreen
                      messages={selectedThread ? selectedThread.messages : []}
                      isLoading={isLoading}
                    />
                  </Content>
                  <div
                    style={{
                      padding: isMobile ? '16px 12px 32px 12px' : '24px 48px',
                      boxShadow: '0 0 10px -4px #e4e4e4',
                      zIndex: 1,
                    }}
                  >
                    <UserInputField
                      onSendMessage={handleSendMessage}
                      style={{ position: 'sticky', bottom: 0, width: '100%' }}
                    />
                  </div>
                </Layout>
              )}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Affix>
  );
}

export default ChatbotPage;
