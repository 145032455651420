// src/components/Modal.js
import React from 'react';
import { Modal as AntdModal } from 'antd';

function Modal({ isVisible, handleClose, children }) {
  return (
    <AntdModal visible={isVisible} onCancel={handleClose} footer={null}>
      {children}
    </AntdModal>
  );
}

export default Modal;
