// src/components/generator/FileInput.js

import React, { useState, useEffect } from 'react';
import { Input, Button, Checkbox, Spin, Typography } from 'antd';
import {
  CloseOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CheckCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { httpsCallable, getFunctions } from '@firebase/functions';
import { getDatabase, ref, onValue, off } from '@firebase/database';
import { app } from '../../firebase/firebase'; // Adjust the path as needed
import { v4 as uuidv4 } from 'uuid';

const functions = getFunctions(app);
const callN8nWorkflow = httpsCallable(functions, 'callN8nWorkflow');
const database = getDatabase(app);

const FileInput = ({ urls, setUrls, onWorkflowIdChange, index }) => {
  const [workflowOutput, setWorkflowOutput] = useState('');
  const [workflowId, setWorkflowId] = useState(null);
  const [workflowStatus, setWorkflowStatus] = useState(
    Array(urls.length).fill('idle'),
  );
  const [advancedContexts, setAdvancedContexts] = useState(
    Array(urls.length).fill(false),
  );

  const addUrlInput = () => {
    if (urls[urls.length - 1] !== '') {
      setUrls([...urls, '']);
      setWorkflowStatus([...workflowStatus, 'idle']);
      setAdvancedContexts([...advancedContexts, false]);
    }
  };

  const removeUrlInput = (index) => {
    setUrls(urls.filter((url, i) => i !== index));
    setWorkflowStatus(workflowStatus.filter((status, i) => i !== index));
    setAdvancedContexts(advancedContexts.filter((context, i) => i !== index));
  };

  useEffect(() => {
    if (workflowId) {
      const dbRef = ref(database, `n8nOutputs/${workflowId}`);
      const listener = onValue(dbRef, (snapshot) => {
        const n8nOutput = snapshot.val();
        if (n8nOutput) {
          console.log('Received output from n8n:', n8nOutput);
          setWorkflowStatus(
            workflowStatus.map((status, i) =>
              i === index ? 'success' : status,
            ),
          );
        }
      });

      // Clean up the listener when the component is unmounted or the workflowId changes
      return () => off(dbRef, listener);
    }
  }, [workflowId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        padding: '16px',
        border: '1px solid #e1e1e1',
        borderRadius: '8px',
        background: '#fffbf2',
      }}
    >
      <Typography.Title level={5} style={{ margin: '8px 0px' }}>
        Context Scraper
      </Typography.Title>
      {urls.map((url, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <Checkbox
            checked={advancedContexts[index]}
            onChange={(e) => {
              const newAdvancedContexts = [...advancedContexts];
              newAdvancedContexts[index] = e.target.checked;
              setAdvancedContexts(newAdvancedContexts);
            }}
            style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
          >
            Full index
          </Checkbox>
          <Input
            value={url}
            placeholder="Enter a URL"
            onChange={(e) =>
              setUrls(urls.map((u, i) => (i === index ? e.target.value : u)))
            }
            disabled={workflowStatus[index] === 'success'}
            style={{ width: '100%' }}
            addonBefore={
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => removeUrlInput(index)}
              />
            }
            hasFeedback
            validateStatus={workflowStatus[index] === 'error' ? 'error' : ''}
          />
          {workflowStatus[index] === 'loading' && (
            <Button
              type="link"
              onClick={() => {
                // Cancel the workflow here
              }}
            >
              Cancel
            </Button>
          )}

          {workflowStatus[index] === 'loading' ? (
            <Spin indicator={<LoadingOutlined />} />
          ) : workflowStatus[index] === 'success' ? (
            <CheckCircleFilled
              style={{
                color: 'green',
                fontSize: '20px',
                width: '32px',
                justifyContent: 'center',
              }}
            />
          ) : workflowStatus[index] === 'error' ? (
            <Button
              type="primary"
              icon={
                <CloudUploadOutlined
                  style={{ fontSize: '20px', marginLeft: '8px' }}
                />
              }
              onClick={async () => {
                const newWorkflowId = uuidv4();
                setWorkflowId(newWorkflowId);
                setWorkflowStatus((prevStatus) => {
                  const newStatus = [...prevStatus];
                  newStatus[index] = 'loading';
                  return newStatus;
                });
                try {
                  const output = await callN8nWorkflow({
                    url: urls[index],
                    advancedContext: advancedContexts[index],
                    workflowId: newWorkflowId,
                  });
                  setWorkflowOutput(output.data);
                  onWorkflowIdChange(newWorkflowId);

                  const dbRef = ref(database, `n8nOutputs/${newWorkflowId}`);
                  const listener = onValue(dbRef, (snapshot) => {
                    const n8nOutput = snapshot.val();
                    if (n8nOutput) {
                      console.log('Received output from n8n:', n8nOutput);
                      setWorkflowStatus((prevStatus) => {
                        const newStatus = [...prevStatus];
                        newStatus[index] = 'success';
                        return newStatus;
                      });
                    }
                  });

                  // Clean up the listener when the component is unmounted or the workflowId changes
                  return () => off(dbRef, listener);
                } catch (error) {
                  console.error('Failed to call n8n workflow:', error);
                  setWorkflowStatus((prevStatus) => {
                    const newStatus = [...prevStatus];
                    newStatus[index] = 'error';
                    return newStatus;
                  });
                }
              }}
            />
          ) : (
            <Button
              type="primary"
              icon={
                <CloudUploadOutlined
                  style={{ fontSize: '20px', marginLeft: '8px' }}
                />
              }
              onClick={async () => {
                const newWorkflowId = uuidv4();
                setWorkflowId(newWorkflowId);
                setWorkflowStatus((prevStatus) => {
                  const newStatus = [...prevStatus];
                  newStatus[index] = 'loading';
                  return newStatus;
                });
                try {
                  const output = await callN8nWorkflow({
                    url: urls[index],
                    advancedContext: advancedContexts[index],
                    workflowId: newWorkflowId,
                  });
                  setWorkflowOutput(output.data);
                  onWorkflowIdChange(newWorkflowId);

                  const dbRef = ref(database, `n8nOutputs/${newWorkflowId}`);
                  const listener = onValue(dbRef, (snapshot) => {
                    const n8nOutput = snapshot.val();
                    if (n8nOutput) {
                      console.log('Received output from n8n:', n8nOutput);
                      setWorkflowStatus((prevStatus) => {
                        const newStatus = [...prevStatus];
                        newStatus[index] = 'success';
                        return newStatus;
                      });
                    }
                  });

                  // Clean up the listener when the component is unmounted or the workflowId changes
                  return () => off(dbRef, listener);
                } catch (error) {
                  console.error('Failed to call n8n workflow:', error);
                  setWorkflowStatus((prevStatus) => {
                    const newStatus = [...prevStatus];
                    newStatus[index] = 'error';
                    return newStatus;
                  });
                }
              }}
            />
          )}
        </div>
      ))}
      <Button
        type="link"
        style={{ fontSize: '12px' }}
        onClick={addUrlInput}
        disabled={urls[urls.length - 1] === ''}
      >
        <PlusOutlined />
        Add new
      </Button>
    </div>
  );
};

export default FileInput;
