import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

function TemplateInput({ value, onChange }) {
  return (
    <TextArea
      value={value}
      onChange={onChange}
      placeholder="Cover letter template"
      style={{ minHeight: '180px' }}
    />
  );
}

export default TemplateInput;
