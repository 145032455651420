// src/pages/MainFeaturePage.js
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Routes, Route, useLocation } from 'react-router-dom';
import AppSider from '../components/Sider';
import AnalyticsPage from './AnalyticsPage';
import IntegrationsPage from './Apps';
import SettingsPage from './SettingsPage';
import ChatbotPage from './ChatbotPage';
import Breadcrumb from '../components/Breadcrumb';
import CoverLetterPage from './CoverLetter';
import ScraperPage from './ScraperPage';
import { useMediaQuery } from 'react-responsive';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const { Content } = Layout;

function MainFeaturePage() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(
    location.pathname.split('/').pop(),
  );
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    setCurrentPath(location.pathname.split('/').pop());
  }, [location]);

  const noPaddingPaths = ['chatbot', 'cover-letter', 'scraper'];

  return (
    <Layout>
      <AppSider isOtherSiderPresent={noPaddingPaths.includes(currentPath)} />
      <Content
        style={
          noPaddingPaths.includes(currentPath)
            ? {}
            : { padding: isSmallScreen ? '0 16px' : '0px 24px' }
        }
      >
        {noPaddingPaths.includes(currentPath) ? null : (
          <Breadcrumb pathname={location.pathname} />
        )}
        <Routes>
          <Route path="chatbot" element={<ChatbotPage />} />
          <Route path="cover-letter" element={<CoverLetterPage />} />
          <Route path="scraper" element={<ScraperPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="apps" element={<IntegrationsPage />} />
          <Route path="analytics" element={<AnalyticsPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default MainFeaturePage;
