import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebase';
import { onValue, ref } from 'firebase/database';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
} from 'chart.js';
import { Card, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageHeader from '../components/PageHeader';

Chart.register(CategoryScale, LinearScale, BarController, BarElement);

function Analytics() {
  const [data, setData] = useState({ threads: 0, avgLength: 0 });

  useEffect(() => {
    const dbRef = ref(db, 'threads');
    onValue(dbRef, (snapshot) => {
      const threads = snapshot.val();
      console.log(threads);
      if (threads) {
        const threadCount = Object.keys(threads).length;
        const totalMessages = Object.values(threads).reduce((total, thread) => {
          const messages = thread.messages;
          const messageCount = Array.isArray(messages)
            ? messages.length
            : messages
            ? Object.keys(messages).length
            : 0;
          return total + messageCount;
        }, 0);
        const avgLength = threadCount > 0 ? totalMessages / threadCount : 0;
        setData({ threads: threadCount, avgLength });
      }
    });
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const chartDataThreads = {
    labels: ['Threads'],
    datasets: [
      {
        label: '# of Threads',
        data: [data.threads],
        backgroundColor: ['rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartDataAvgLength = {
    labels: ['Average Length'],
    datasets: [
      {
        label: 'Average Length',
        data: [data.avgLength],
        backgroundColor: ['rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <PageHeader
        title="Analytics"
        description="Description"
        showButton={true}
        buttonText="Add new manually"
        buttonIcon={<PlusOutlined />}
        iconPosition="left"
        modalContent={<div>Modal content goes here</div>}
      />
      <Row gutter={[16, 16]} justify="start">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card title="Threads" style={{ height: '100%' }}>
            <Bar data={chartDataThreads} options={options} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card title="Average Length" style={{ height: '100%' }}>
            <Bar data={chartDataAvgLength} options={options} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Analytics;
