// src/components/UserInputField.js
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Input, Button } from 'antd';
import { getChatbotResponse } from '../openai';

const { TextArea } = Input;

function UserInputField({ onSendMessage }) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const handleSend = async () => {
    setLoading(true);
    // Immediately send the user's message
    const userMessage = { author: 'User', content: message };

    // Then get the chatbot's response and send it
    const chatbotContent = await getChatbotResponse(message);
    const chatbotMessage = {
      author: 'Chatbot',
      content: chatbotContent ? chatbotContent : 'Error in getting response',
    };

    onSendMessage(userMessage, chatbotMessage);

    setMessage('');
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <div
      className="chat-input"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '8px',
        alignItems: 'flex-end',
      }}
    >
      <TextArea
        className="chat-text-area"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        autoSize={{ minRows: isMobile ? 1 : 3, maxRows: 8 }}
        style={{ width: '100%', minHeight: '48px', maxHeight: '1000px' }}
      />
      <Button
        className="chat-send"
        type="primary"
        loading={loading}
        onClick={handleSend}
        style={{ width: 'min-content' }}
      >
        Send
      </Button>
    </div>
  );
}

export default UserInputField;
