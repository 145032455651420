// src/components/CoverLetterSider.js
import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Layout, Menu, Affix, Button, Modal, Typography, Input } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MenuFoldOutlined,
  DownOutlined,
  BuildOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

function CoverLetterSider({
  coverLetters,
  onSelectCoverLetter,
  onDeleteCoverLetter,
  onDeleteAll,
  onCreateNew,
  onCoverLetterTitleChange,
  isDrawer,
  newCoverLetterAlias,
  editingTitle,
  setEditingTitle,
}) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 900px) and (max-width: 1199px)',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingCoverLetter, setEditingCoverLetter] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [deleteAll, setDeleteAll] = useState(false);
  const [isTitleChanged, setIsTitleChanged] = useState(false);

  const handleEditClick = (index) => {
    setEditingCoverLetter(index);
    setEditedTitle(coverLetters[index].title);
  };

  const handleTitleSubmit = (coverLetter) => {
    setIsTitleChanged(true);
    setEditingCoverLetter(null);
    onCoverLetterTitleChange(coverLetter, editingTitle); // Use editedTitle instead of coverLetter.title
    setEditedTitle('');
  };

  // Add a new function to handle the "Delete all" button click
  const handleDeleteAllClick = () => {
    setDeleteAll(true);
    setIsModalVisible(true);
  };

  const handleDeleteClick = (e, coverLetter) => {
    e.stopPropagation();
    setSelectedCoverLetter(coverLetter);
    setIsModalVisible(true);
    setDeleteAll(false);
  };

  const handleCreateNewClick = () => {
    const newLetter = {
      title: 'New Letter ',
      template: '',
      jobAd: '',
      prompt: '',
      generatedText: '',
    };
    setSelectedCoverLetter(newLetter);
    if (typeof onCreateNew === 'function') {
      onCreateNew(newLetter);
    } else {
      console.error('onCreateNew is not a function');
    }
  };

  const handleSelectCoverLetterLocal = (coverLetter) => {
    setSelectedCoverLetter(coverLetter || null);
    onSelectCoverLetter(coverLetter);
  };

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (deleteAll) {
          onDeleteAll();
        } else {
          onDeleteCoverLetter(selectedCoverLetter);
        }
        setIsModalVisible(false);
      }
    },
    [deleteAll, onDeleteAll, onDeleteCoverLetter, selectedCoverLetter],
  );

  useEffect(() => {
    if (!selectedCoverLetter && coverLetters.length > 0) {
      setSelectedCoverLetter(coverLetters[0]);
    }
  }, [coverLetters, selectedCoverLetter]);

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('keydown', handleEnterPress);
    } else {
      document.removeEventListener('keydown', handleEnterPress);
    }
    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [isModalVisible, handleEnterPress]);

  // Add a new useEffect to handle the newCoverLetterAlias prop
  useEffect(() => {
    if (newCoverLetterAlias) {
      const newLetter = coverLetters.find(
        (coverLetter) => coverLetter.alias === newCoverLetterAlias,
      );
      if (newLetter) {
        setSelectedCoverLetter(newLetter);
      }
    }
  }, [newCoverLetterAlias, coverLetters]);

  return (
    <Affix offsetTop={64}>
      <Sider
        style={{
          height: '96vh',
          zIndex: 1,
          overflow: 'hidden',
          backgroundColor: '#fff',
        }}
        width={200}
        collapsed={isDrawer ? false : collapsed} // Always expanded when inside a Drawer
        collapsedWidth={48}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' || type === 'responsive') {
            setCollapsed(collapsed);
          }
        }}
      >
        {collapsed && !isDrawer ? (
          <div
            style={{
              height: '100%',
              borderRight: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop: '48px',
            }}
          >
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                width: '180px',
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                color: 'grey',
              }}
            >
              {collapsed ? 'View all letters' : 'Hide letters'}
              <DownOutlined />
            </Button>
          </div>
        ) : (
          <Menu
            mode="inline"
            style={{
              height: '100%',
              borderRight: 0,
            }}
            selectedKeys={
              selectedCoverLetter && selectedCoverLetter.id
                ? [selectedCoverLetter.id.toString()]
                : []
            }
          >
            {!isDrawer && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  type="text"
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    padding: '0 12px',
                  }}
                >
                  <MenuFoldOutlined />
                </Button>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                padding: isDrawer ? '0' : '8px 12px 16px 12px',
                borderBottom: '1px solid #f0f0f0',
                margin: '0 0 4px 0',
              }}
            >
              <Typography.Title
                level={5}
                style={{
                  margin: '0 0 16px 0',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: 'darkgrey',
                }}
              >
                <BuildOutlined style={{ marginRight: '8px' }} />
                Generative Agent
              </Typography.Title>
              <Button
                type="default"
                size="middle"
                icon={<PlusOutlined />}
                onClick={handleCreateNewClick} // Ensure this is the only place where handleCreateNewClick is called
                style={{ width: '100%' }}
              >
                New project
              </Button>
            </div>

            <Menu.ItemGroup key="g1" title="Saved projects" />
            {[...coverLetters]
              .reverse()
              .filter((coverLetter) => !coverLetter.isDraft)
              .map((coverLetter, index) => (
                <Menu.Item
                  key={coverLetter.id}
                  style={{
                    padding: '6px 0px 6px 14px',
                    margin: '0px 4px',
                    fontWeight: 400,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {editingCoverLetter === index ? (
                      <Input
                        autoFocus
                        value={editingTitle}
                        onChange={(e) => setEditingTitle(e.target.value)}
                        onBlur={() => handleTitleSubmit(coverLetter)}
                        onPressEnter={() => handleTitleSubmit(coverLetter)}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                        onClick={() =>
                          handleSelectCoverLetterLocal(coverLetter)
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            adjustItems: 'center',
                          }}
                        >
                          <div className="ellipsis">{coverLetter.title}</div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              type="text"
                              icon={
                                <EditOutlined
                                  style={{ fontSize: '14px', color: 'grey' }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick(index);
                              }}
                            />
                            <Button
                              type="text"
                              icon={
                                <DeleteOutlined
                                  style={{ fontSize: '14px', color: 'grey' }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(e, coverLetter);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Menu.Item>
              ))}
            {coverLetters.length > 1 && (
              <div style={{ padding: '10px' }}>
                <Button
                  type="link"
                  onClick={handleDeleteAllClick}
                  style={{ fontSize: '12px', width: '100%' }}
                >
                  Delete all
                </Button>
              </div>
            )}
          </Menu>
        )}
        <Modal
          title="Confirmation"
          visible={isModalVisible}
          onOk={(e) => {
            e.stopPropagation();
            if (deleteAll) {
              onDeleteAll();
            } else {
              onDeleteCoverLetter(selectedCoverLetter);
            }
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          okText={deleteAll ? 'Yes, delete all' : 'Yes, delete'}
          cancelText="Cancel"
          autoFocusButton="ok"
        >
          <p>
            Are you sure to delete{' '}
            {deleteAll ? 'all cover letters' : 'this cover letter'}? You won't
            be able to restore {deleteAll ? 'them' : 'it'} after confirming
            deletion.
          </p>
        </Modal>
      </Sider>
    </Affix>
  );
}

export default CoverLetterSider;
