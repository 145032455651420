import React, { useEffect, useState, useContext } from 'react';
import ApiIntegrationsContext from '../ApiIntegrationsContext';
import { functions } from '../firebase/firebase';
import { httpsCallable } from 'firebase/functions';
import {
  Layout,
  Button,
  Input,
  Typography,
  Spin,
  Menu,
  Row,
  Checkbox,
  Switch,
  Divider,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import TemplateInput from './generator/TemplateInput';
import JobAdInput from './generator/JobAdInput';
import PromptInput from './generator/PromptInput';
import LinkInput from './generator/LinkInput';
import FileInput from './generator/FileInput';
import PersonalityInput from './generator/PersonalityInput';
import { getContentFromUrl, getRelevantKeywords } from '../openai';
import { getDatabase, ref, onValue, off } from '@firebase/database';
import { app } from '../firebase/firebase';

const database = getDatabase(app);

const { Content } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

function CoverLetterGenerator({
  template,
  setTemplate,
  jobAd,
  setJobAd,
  prompt,
  setPrompt,
  link,
  setLink,
  file,
  setFile,
  personal,
  setPersonal,
  handleGenerateCoverLetter,
  newCoverLetter,
  onSelectCoverLetter,
  handleInputChange,
  isChanged,
  loading,
  url,
  handleSetLink,
}) {
  const [showContainer, setShowContainer] = useState(false);
  const { apiIntegrations, setApiIntegrations } = useContext(
    ApiIntegrationsContext,
  );
  const [indexedContent, setIndexedContent] = useState('');
  const callN8nWorkflow = httpsCallable(functions, 'callN8nWorkflow');
  const [workflowOutput, setWorkflowOutput] = useState(''); // Add this line
  const [urls, setUrls] = useState(['']);
  const [workflowId, setWorkflowId] = useState(null);
  const handleIndex = async (url) => {
    try {
      // Concatenate the user's input
      const userInput = `${template} ${jobAd} ${prompt}`;

      // Get relevant keywords
      const keywords = await getRelevantKeywords(userInput, apiIntegrations);
      console.log('Generated keywords:', keywords);

      // Index the URL with the relevant keywords
      const content = await getContentFromUrl(url, keywords);
      console.log(content);
      setIndexedContent(content);
      handleSetLink(content); // Update the link state variable in CoverLetter.js
    } catch (error) {
      console.error('Failed to index URL:', error);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const isMobile = useMediaQuery({ query: '(max-width: 479px)' });
  const isSmallScreen = useMediaQuery({
    query: '(min-width: 480px) and (max-width: 600px)',
  });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 900px) and (max-width: 1199px)',
  });
  const [addedInputs, setAddedInputs] = useState([
    { type: 'prompt', id: Math.random() },
    { type: 'template', id: Math.random() },
    { type: 'jobAd', id: Math.random() },
  ]);

  const handleTriggerWorkflow = async (url) => {
    try {
      const result = await callN8nWorkflow({ url });
      setWorkflowOutput(result.data);
    } catch (error) {
      console.error('Failed to call n8n workflow:', error);
    }
  };

  const handleToggle = (integration) => {
    setApiIntegrations((prevApiIntegrations) => ({
      ...prevApiIntegrations,
      [integration]: {
        ...prevApiIntegrations[integration],
        isEnabled: !prevApiIntegrations[integration].isEnabled,
      },
    }));
  };
  const handleToggleInput = (inputType) => {
    if (addedInputs.some((input) => input.type === inputType)) {
      setAddedInputs(addedInputs.filter((input) => input.type !== inputType));
    } else {
      setAddedInputs([...addedInputs, { type: inputType, id: Math.random() }]);
    }
  };

  useEffect(() => {
    if (workflowId) {
      const dbRef = ref(database, `n8nOutputs/${workflowId}`);
      const listener = onValue(dbRef, (snapshot) => {
        const n8nOutput = snapshot.val();
        if (n8nOutput) {
          console.log('Received output from n8n:', n8nOutput);
        }
      });

      return () => off(dbRef, listener);
    }
  }, [workflowId]);

  useEffect(() => {
    if (newCoverLetter) {
      setTemplate(newCoverLetter.template);
      setJobAd(newCoverLetter.jobAd);
      setPrompt(newCoverLetter.prompt);
      setLink(newCoverLetter.link);
      setFile(newCoverLetter.file);
      setPersonal(newCoverLetter.personal);

      const newAddedInputs = [
        'template',
        'jobAd',
        'prompt',
        'link',
        'file',
        'personal',
      ].filter((type) => newCoverLetter[type] !== '');
      setAddedInputs(newAddedInputs.map((type) => ({ type, id: type })));
    }
    // Unconditionally add 'prompt', 'template', and 'jobAd' to 'addedInputs'
    setAddedInputs([
      { type: 'prompt', id: 'prompt' },
      { type: 'template', id: 'template' },
      { type: 'jobAd', id: 'jobAd' },
    ]);
  }, [
    newCoverLetter,
    setTemplate,
    setJobAd,
    setPrompt,
    setLink,
    setFile,
    setPersonal,
  ]);

  useEffect(() => {
    const newAddedInputs = [
      'template',
      'jobAd',
      'prompt',
      'link',
      'file',
      'personal',
    ].filter((type) => {
      switch (type) {
        case 'template':
          return (
            template !== '' &&
            !addedInputs.some((input) => input.type === 'template')
          );
        case 'jobAd':
          return (
            jobAd !== '' && !addedInputs.some((input) => input.type === 'jobAd')
          );
        case 'prompt':
          return (
            prompt !== '' &&
            !addedInputs.some((input) => input.type === 'prompt')
          );
        case 'link':
          return (
            link !== '' && !addedInputs.some((input) => input.type === 'link')
          );
        case 'file':
          return (
            file !== '' && !addedInputs.some((input) => input.type === 'file')
          );
        case 'personal':
          return (
            personal !== '' &&
            !addedInputs.some((input) => input.type === 'personal')
          );
        default:
          return false;
      }
    });
    setAddedInputs((prevAddedInputs) => [
      ...prevAddedInputs,
      ...newAddedInputs.map((type) => ({ type, id: type })),
    ]); // Use type as id
  }, [template, jobAd, prompt, link, file, personal]);

  return (
    <Content
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        flexWrap: isMediumScreen || isSmallScreen ? 'wrap' : 'nowrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
          maxWidth: isMediumScreen || isSmallScreen ? '100%' : '1200px',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  gap: '12px',
                  flexWrap: 'wrap',
                  flexBasis: isSmallScreen ? '100%' : 'auto',
                }}
              >
                <Checkbox
                  checked={addedInputs.some(
                    (input) => input.type === 'template',
                  )}
                  onChange={() => handleToggleInput('template')}
                >
                  Template Input
                </Checkbox>
                <Checkbox
                  checked={addedInputs.some((input) => input.type === 'jobAd')}
                  onChange={() => handleToggleInput('jobAd')}
                >
                  Job Ad Input
                </Checkbox>
                <Checkbox
                  checked={addedInputs.some((input) => input.type === 'prompt')}
                  onChange={() => handleToggleInput('prompt')}
                >
                  Prompt Input
                </Checkbox>
                <Checkbox
                  checked={addedInputs.some((input) => input.type === 'file')}
                  onChange={() => handleToggleInput('file')}
                >
                  File Upload
                </Checkbox>
                <Checkbox
                  checked={addedInputs.some(
                    (input) => input.type === 'personal',
                  )}
                  onChange={() => handleToggleInput('personal')}
                >
                  Personal Details
                </Checkbox>
              </Row>
              <Button
                type="link"
                size="small"
                style={{
                  width: isMobile ? '100%' : 'min-content',
                  fontSize: '12px',
                  padding: 0,
                  flexBasis: isSmallScreen ? '100%' : 'auto',
                }}
                onClick={() => setShowContainer(!showContainer)}
              >
                {showContainer ? (
                  <>
                    {' '}
                    Hide apps <LeftOutlined />{' '}
                  </>
                ) : (
                  <>
                    {' '}
                    See apps <RightOutlined />{' '}
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div stlye={{ height: '100%' }}>
          <Divider type="horizontal" style={{ margin: 0 }} />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
            gridGap: '12px',
            maxWidth: '100%',
            width: '100%',
            overflow: 'auto',
          }}
        >
          {addedInputs.map((input) => {
            switch (input.type) {
              case 'template':
                return (
                  <TemplateInput
                    key={input.id}
                    value={template}
                    onChange={handleInputChange(setTemplate)}
                    style={{ minWidth: '280px', boxSizing: 'border-box' }}
                  />
                );
              case 'jobAd':
                return (
                  <JobAdInput
                    key={input.id}
                    value={jobAd}
                    onChange={handleInputChange(setJobAd)}
                    style={{ minWidth: '280px', boxSizing: 'border-box' }}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        {addedInputs.some((input) => input.type === 'prompt') && (
          <PromptInput
            value={prompt}
            onChange={handleInputChange(setPrompt)}
            style={{ width: '100%' }}
          />
        )}
        {addedInputs.some((input) => input.type === 'file') && (
          <FileInput
            urls={urls}
            setUrls={setUrls}
            onWorkflowIdChange={setWorkflowId}
            handleTriggerWorkflow={handleTriggerWorkflow} // Pass the new prop here
          />
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: addedInputs.some(
              (input) => input.type === 'personal',
            )
              ? '1fr 1fr'
              : '1fr',
            gap: '12px',
            maxWidth: '100%',
            width: '100%',
            overflow: 'auto',
          }}
        >
          {addedInputs.map((input) => {
            switch (input.type) {
              case 'personal':
                return (
                  <PersonalityInput
                    key={input.id}
                    value={personal}
                    onChange={handleInputChange(setPersonal)}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            type="link"
            style={{ width: 'max-content', padding: '4px 0' }}
          >
            <PlusOutlined />
            Add custom text field
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            type="primary"
            onClick={handleGenerateCoverLetter}
            style={{ width: isMobile ? '100%' : 'unset' }}
            disabled={!isChanged}
          >
            Generate
            {loading && (
              <Spin
                indicator={antIcon}
                style={{ color: 'white', marginLeft: '10px' }}
              />
            )}
          </Button>
        </div>
      </div>
      <div
        style={{
          minWidth: 'max-content',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'stretch',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {showContainer && (
            <div
              style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
            >
              <Divider
                type="vertical"
                style={{ height: '100%', margin: '0 24px' }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  alignItems: 'center',
                  paddingBottom: '16px',
                  height: '100%',
                }}
              >
                <Typography.Title
                  level={5}
                  style={{
                    textAlign: 'left',
                    marginTop: 0,
                    width: 'max-content',
                    paddingRight: '16px',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  Apps
                </Typography.Title>
                {Object.keys(apiIntegrations).map((integration) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '24px',
                      minHeight: '32px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography style={{ fontSize: '12px' }}>
                      {integration}
                    </Typography>
                    <Switch
                      size="small"
                      checked={apiIntegrations[integration].isEnabled}
                      onChange={() => handleToggle(integration)}
                    />
                  </div>
                ))}
                <Button
                  type="link"
                  size="small"
                  style={{
                    width: isMobile ? '100%' : 'unset',
                    fontSize: '12px',
                    padding: 0,
                    textAlign: 'left',
                    marginTop: '8px',
                  }}
                  onClick={() => (window.location.href = '/my-tools/apps')}
                >
                  View all options
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Content>
  );
}

export default CoverLetterGenerator;
