import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Layout, Badge } from 'antd';

function PrivacyPolicy() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <Layout
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        height: '100vh'
      }}
    >
      <Layout
        style={{
          maxWidth: '80ch',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: isMobile ? '0' : '8px',
          padding: isMobile ? '16px' : '48px 48px 72px 48px',
          marginBottom: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '8px',
            alignItems: 'center',
            marginBottom: '1em',
          }}
        >
        </div>
        <h1
          style={{
            fontWeight: 'bold',
            marginBottom: '0.5em',
          }}
        >
          Privacy Policy
        </h1>
        <p
          style={{
            lineHeight: '1.6',
            color: '#333',
          }}
        >
          Welcome to our Privacy Policy. Your privacy is critically important to us. Our app is located at:
          [Your App Address]
          It is our policy to respect your privacy regarding any information we may collect while operating our app.
          We respect your privacy and are committed to protecting personally identifiable information you may provide us through the app.
          We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our app, how we use this information, and under what circumstances we may disclose the information to third parties.
          This Privacy Policy applies only to information we collect through the app and does not apply to our collection of information from other sources.
          This Privacy Policy, together with the Terms and conditions posted on our app, set forth the general rules and policies governing your use of our app.
          Depending on your activities when visiting our app, you may be required to agree to additional terms and conditions.
          We collect several different types of information for various purposes to provide and improve our service to you.
          While using our app, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data").
          Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, and other information.
          We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you.
          You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
          We also collect information that your browser sends whenever you visit our app or when you access the app by or through a mobile device ("Usage Data").
          This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our app that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          When you access the app with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
          We use cookies and similar tracking technologies to track the activity on our app and hold certain information.
          Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our app.
        </p>
      </Layout>
    </Layout>
  );
}

export default PrivacyPolicy;
