// src/pages/AccountSettings.js
import React, { useContext } from 'react';
import { Form, Input, Button, Layout, Typography } from 'antd';
import { AuthContext } from '../AuthContext';
import { ref, update } from 'firebase/database';
import { db } from '../firebase/firebase';

const { Content } = Layout;

function AccountSettings() {
  const { currentUser } = useContext(AuthContext);

  const onFinish = (values) => {
    const userRef = ref(db, 'users/' + currentUser.uid);
    update(userRef, values);
  };

  return (
    <Content style={{ maxWidth: '320px', margin: '0 auto', height: '80vh' }}>
      <Typography.Title
        level={1}
        style={{ textAlign: 'center', margin: '24px 0 32px' }}
      >
        Account settings
      </Typography.Title>
      <Form
        name="account_settings"
        onFinish={onFinish}
        initialValues={currentUser}
        onValuesChange={(changedValues, allValues) => {
          // Enable the save button when a change has been made
          const saveButton = document.querySelector('.save-button');
          if (saveButton) {
            saveButton.disabled = false;
          }
        }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>

        <Form.Item name="phone">
          <Input size="large" placeholder="Phone Number" />
        </Form.Item>
        <Form.Item name="job">
          <Input size="large" placeholder="Job" />
        </Form.Item>
        <Form.Item name="role">
          <Input size="large" placeholder="Role" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your new password!' },
          ]}
        >
          <Input.Password
            placeholder="New Password"
            size="large"
            style={{ marginBottom: '16px' }}
          />
        </Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="save-button"
          disabled
          style={{ width: '100%' }}
        >
          Save Changes
        </Button>
      </Form>
    </Content>
  );
}

export default AccountSettings;
