// src/pages/ScraperPage.js
import React, { useState, useContext, useEffect } from 'react';
import SendToN8n from '../components/scraper/SendToN8n';
import Output from '../components/scraper/Output';
import { db, callN8nWorkflow } from '../firebase/firebase'; // Update the import path
import ApiIntegrationsContext from '../ApiIntegrationsContext';
import { Row, Col, Typography, Layout, Select } from 'antd'; // Import Select from antd
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import PageHeader from '../components/PageHeader';
import ScraperSider from '../components/ScraperSider';
import { useMediaQuery } from 'react-responsive';

const ScraperPage = ({ user }) => {
  const { apiIntegrations } = useContext(ApiIntegrationsContext);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const isSmallScreen = useMediaQuery({
    query: '(min-width: 481px) and (max-width: 600px)',
  });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });

  useEffect(() => {
    if (!apiIntegrations['Web Scraper']?.isEnabled) {
      navigate('/my-tools/apps');
    }
  }, [apiIntegrations, navigate]);

  const [sessionId, setSessionId] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasOutputData, setHasOutputData] = useState(false);
  const [selectedSelectors, setSelectedSelectors] = useState([]); // Add state for selected selectors

  const handleDataReceived = (data) => {
    setData(data);
    setLoading(false);
    setHasOutputData(Object.keys(data).length > 0);
  };

  const handleSelectorChange = (value) => {
    // Add handler for selector change
    setSelectedSelectors(value);
  };

  const [yourScrapersData, setYourScrapersData] = useState([]);
  return (
    <>
      <Layout style={{ overflow: 'hidden', height: '90vh' }}>
        <ScraperSider scrapers={yourScrapersData || []} />
        <Layout
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            flexDirection: 'column',
            padding: '24px',
          }}
        >
          <PageHeader
            title="Web Scraper"
            description="Allows you to scrape data from a website."
            showButton={false}
            modalContent={<div>Modal content goes here</div>}
          />
          <Layout
            className="site-layout-background"
            style={{
              margin: 0,
              minHeight: 280,
              width: '100%',
              padding: '0 0 128px 0',
            }}
          >
            <Row gutter={[16, 16]} justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={hasOutputData ? 12 : 24}
                xl={hasOutputData ? 12 : 24}
                style={{ maxWidth: '600px' }}
              >
                <SendToN8n
                  sessionId={sessionId}
                  setSessionId={setSessionId}
                  loading={loading}
                  setLoading={setLoading}
                  user={user}
                  callN8nWorkflow={callN8nWorkflow}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Output
                  sessionId={sessionId}
                  db={db}
                  onData={handleDataReceived}
                  setLoading={setLoading}
                  user={user}
                />
              </Col>
            </Row>
          </Layout>
        </Layout>{' '}
      </Layout>
    </>
  );
};

export default ScraperPage;
