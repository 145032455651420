import React, { useState, useEffect } from 'react';
import { Input, Button, Spin, Alert } from 'antd';
import {
  CloseOutlined,
  CheckCircleFilled,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

function LinkInput({ value, onChange, onIndex }) {
  const [urls, setUrls] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [indexed, setIndexed] = useState([false]);

  const handleUrlChange = (event, index) => {
    const newUrls = [...urls];
    newUrls[index] = event.target.value;
    setUrls(newUrls);
    onChange(event); // Update the link state variable in CoverLetterGenerator.js
  };

  const addUrlInput = () => {
    if (urls[urls.length - 1] !== '') {
      setUrls([...urls, '']);
      setIndexed([...indexed, false]);
    }
  };

  const removeUrlInput = (index) => {
    setUrls(urls.filter((url, i) => i !== index));
    setIndexed(indexed.filter((url, i) => i !== index));
  };

  const handleIndex = async (url, index) => {
    setLoading(true);
    await onIndex(url, ['software', 'developer', 'engineer', 'programming']); // Add keywords here
    setLoading(false);
    setSuccess(true);
    setIndexed(indexed.map((value, i) => (i === index ? true : value)));
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
      }}
    >
      {urls.map((url, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => removeUrlInput(index)}
          />
          <Input
            value={url}
            onChange={(event) => handleUrlChange(event, index)}
            placeholder="Enter a URL"
            disabled={indexed[index]}
          />
          {!indexed[index] && (
            <Button
              type="primary"
              style={{ marginLeft: '4px' }}
              onClick={() => handleIndex(url, index)}
              disabled={!url || indexed[index]}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: '#fff' }}
                      spin
                    />
                  }
                />
              ) : (
                'Index'
              )}
            </Button>
          )}
          {indexed[index] && <CheckCircleFilled style={{ color: '#52c41a' }} />}
        </div>
      ))}
      {success && (
        <Alert
          message="The url is now indexed. The AI will read the content of the link you provided"
          type="success"
          showIcon
        />
      )}
      <Button
        type="default"
        style={{ fontSize: '12px' }}
        onClick={addUrlInput}
        disabled={urls[urls.length - 1] === ''}
      >
        <PlusOutlined />
        Add new
      </Button>
    </div>
  );
}

export default LinkInput;
