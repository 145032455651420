// src/pages/HomePage.js
import React from 'react';
import { Layout, Row, Col, Typography, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../AuthContext';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function HomePage() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const { currentUser } = useAuth();
  return (
    <Content
      style={{
        padding: isSmallScreen ? '0 16px' : '0 50px',
        height: '88vh',
        background: 'linear-gradient(to right, #add8e6, #ffffff)',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '14%',
      }}
    >
          <Row gutter={[12, 48]} >
        <Col xs={24} sm={24} md={14} lg={14} style={{ textAlign: 'left' }}>
          <Typography.Title level={1}>
            {' '}
            Control the web your way, and easily.
          </Typography.Title>
          <Typography.Paragraph
            style={{ marginBottom: '24px', fontSize: '16px' }}
          >
            Webeasy is your customizable control panel for numerous web tools.
            <br></br>Advanced mediums under your service, and easy to use.
          </Typography.Paragraph>

          <Button
            onClick={() =>
              (window.location.href = currentUser ? '/my-tools' : '/login')
            }
            type="primary"
            size="large"
          >
            {currentUser ? 'Start using' : 'Log in'}
          </Button>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} style={{ textAlign: 'left' }}>
          <img src="intro-hero.png" style={{ width: isSmallScreen ? '320px' : '480px', height: "320px", objectFit: "cover" }} />
        </Col>
      </Row>
    </Content>
  );
}

export default HomePage;
