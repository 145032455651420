// src/components/Footer.js
import React from 'react';
import { Layout, Divider } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { Footer } = Layout;

function AppFooter() {
  const isMobile = useMediaQuery({ query: '(max-width: 479px)' });
  return (
    <Footer style={{ padding: "0 24px 24px 24px"}}>
      <Divider />
    <div
      style={{
        textAlign: 'center',
        padding: '0px',
        fontSize: '12px',
        gap: '16px',
        color: 'grey',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      }}
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent : isMobile ? 'center' : 'flex-start'}}>
        <a href="/privacy-policy" style={{ whiteSpace: 'nowrap' }}>Privacy Policy</a>
        <Divider type="vertical" />
        <a href="/terms-of-service" style={{ whiteSpace: 'nowrap' }}>Terms of Service</a>
      </div>
      <div style={{ display: "flex", gap: "12px", alignItems: "center", justifyContent : isMobile ? 'center' : 'flex-start'}}>
        <p style={{ margin: 0, whiteSpace: 'nowrap' }}>Webeasy ©2023</p>
      </div>
      <div style={{ width: "204px", display: "flex", justifyContent: isMobile ?  'center' : 'flex-end', width: isMobile ? "100%" : "max-content" }}>
      <img src="stripe-badge.svg" alt="Webeasy Logo" style={{ width: '100px'}} />
      </div>
</div>
    </Footer>
  );
}

export default AppFooter;
