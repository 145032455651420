// src/components/MobileDrawer.js
import React, { useState, useContext } from 'react';
import { Drawer, Button, Avatar } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'; // Import CloseOutlined
import { AuthContext } from '../AuthContext';
import UserDropdown from './UserDropdown';
import { Link } from 'react-router-dom';

function MobileDrawer() {
  const [visible, setVisible] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          className="logo"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '24px',
          }}
        >
          <a href="/" style={{ height: '48px' }}>
            <img src="/Logo.png" alt="Logo" />
          </a>
        </div>
        <Button
          type="text"
          onClick={showDrawer}
          style={{ width: 'max-content', padding: '0 12px' }}
        >
          <MenuOutlined style={{ fontSize: '20px', color: 'white' }} />
        </Button>
      </div>
      <Drawer
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>Menu</span>
            <Button
              type="text"
              onClick={onClose}
              style={{ width: 'max-content', padding: 0 }}
            >
              <CloseOutlined />
            </Button>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <p>
            <Link to="/my-tools" onClick={onClose}>
              My tools
            </Link>
          </p>
          <p>
            <Link to="/community-hub" onClick={onClose}>
              Community hub
            </Link>
          </p>
          <p>
            <Link to="/about-us" onClick={onClose}>
              About us
            </Link>
          </p>
          <p>
            {currentUser ? (
              <UserDropdown user={currentUser} />
            ) : (
              <Link to="/login" onClick={onClose}>
                Login
              </Link>
            )}
          </p>
        </div>
      </Drawer>
    </>
  );
}

export default MobileDrawer;
