// src/components/ContentWrapper.js
import React from 'react';
import { Layout, Affix } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { Content } = Layout;

function ContentWrapper({ children, width = '320px', height = '80vh' }) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 769px) and (max-width: 1024px)',
  });
  const style = {
    maxWidth: width,
    height: height,
    width: '100%',
  };

  return (
    <Content
      style={{
        padding: isSmallScreen ? '16px' : isMediumScreen ? '24px' : '24px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={style}>{children}</div>
    </Content>
  );
}

export default ContentWrapper;
