// src/ApiIntegrationsContext.js
import React, { createContext, useState, useEffect } from 'react';

const ApiIntegrationsContext = createContext();

export const correctCodes = {
  Cbt: 'cbt0001',
  Gen: 'gen0001',
  Scr: 'scr0001',
};

export const ApiIntegrationsProvider = ({ children }) => {
  const [apiIntegrations, setApiIntegrations] = useState(() => {
    const localData = JSON.parse(localStorage.getItem('apiIntegrations'));
    return localData
      ? localData
      : {
          AdvancedChatbot: { isEnabled: false, apiKey: '', isAdded: false },
          GenerativeAgent: { isEnabled: false, apiKey: '', isAdded: false },
          WebScraper: { isEnabled: false, apiKey: '', isAdded: false }, // Add this line
        };
  });

  useEffect(() => {
    localStorage.setItem('apiIntegrations', JSON.stringify(apiIntegrations));
  }, [apiIntegrations]);

  return (
    <ApiIntegrationsContext.Provider
      value={{ apiIntegrations, setApiIntegrations }}
    >
      {children}
    </ApiIntegrationsContext.Provider>
  );
};

export default ApiIntegrationsContext;
