// src/components/BlogPost.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Layout, Badge } from 'antd';

function BlogPost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    import(`../pages/articles/${id}.js`)
      .then((postModule) => {
        setPost(postModule.default);
      })
      .catch((error) => {
        console.error(`Error loading post ${id}:`, error);
      });
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <Layout
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${post.coverImage})`,
        margin: isMobile ? '0' : '-56px 0 0 0',
        padding: '0px',
      }}
    >
      <Layout
        style={{
          maxWidth: '80ch',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: isMobile ? '0' : '8px',
          padding: isMobile ? '16px' : '48px 48px 72px 48px',
          marginBottom: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '8px',
            alignItems: 'center',
            marginBottom: '1em',
          }}
        >
          {post.tags.map((tag, index) => (
            <Badge
              key={index}
              count={tag}
              style={{
                width: 'max-content',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                border: '1px solid rgb(191 191 191)',
                color: 'rgb(129 129 129)',
                fontSize: '14px',
                borderRadius: '50px',
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
              }}
            ></Badge>
          ))}
        </div>
        <h1
          style={{
            fontSize: isMobile ? '2em' : '2.25em',
            fontWeight: 'bold',
            marginBottom: '0.5em',
          }}
        >
          {post.title}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
            alignItems: 'center',
            margin: '1em 0',
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <Avatar size="large" src={post.authorAvatar} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href={post.authorLink}>{post.author}</a>
              <p style={{ fontSize: '12px', color: 'grey', margin: '4px 0' }}>
                {post.authorTitle}
              </p>
            </div>
          </div>
          <p style={{ fontSize: '12px', color: 'grey' }}>
            Published on {post.date}
          </p>
        </div>
        <div
          style={{
            fontSize: isMobile ? '1em' : '1.125em',
            lineHeight: '1.6',
            color: '#333',
          }}
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </Layout>
    </Layout>
  );
}

export default BlogPost;
