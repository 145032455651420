const Blog2 = {
  id: 'Blog-2',
  author: 'John Doe',
  authorLink: 'https://www.linkedin.com/in/john-doe/',
  authorAvatar: '/logo.png',
  authorTitle: 'Founder of Webeasy',
  date: 'November 1, 2021',
  tags: ['Artificial Intelligence', 'Machine Learning', 'Deep Learning'],
  title: 'Artificial Intelligence: Revolutionizing the Digital World',
  coverImage: '/blog-images/blog-2.jpg',
  summary:
    'Artificial Intelligence (AI) is a branch of computer science that aims to create machines that mimic human intelligence. This could be anything from recognizing speech, to learning, planning, problem-solving and even perception. A key part of AI is machine learning, where machines are trained to process large amounts of data and recognize patterns in the data, much like the human brain does.',
  content: `
    <h2>Introduction to Artificial Intelligence</h2>
  `,
  images: ['url-to-image-1', 'url-to-image-2', 'url-to-image-3'],
};

export default Blog2;
