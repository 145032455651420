import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiIntegrationsContext from '../ApiIntegrationsContext';
import { useMediaQuery } from 'react-responsive';
import {
  Layout,
  Typography,
  Alert,
  Button,
  Input,
  Collapse,
  Empty,
} from 'antd';
import { generateCoverLetter, getContentFromUrl } from '../openai';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SaveOutlined,
  CopyOutlined,
  UploadOutlined,
  EditOutlined,
  BuildOutlined,
} from '@ant-design/icons';
import CoverLetterSider from '../components/CoverLetterSider';
import CoverLetterGenerator from '../components/CoverLetterGenerator';
import AppSider from '../components/Sider';
import { db } from '../firebase/firebase';
import { ref, set, push, onValue, remove, update } from 'firebase/database';
import { AuthContext } from '../AuthContext';
import CLSiderDrawer from '../components/CLSiderDrawer';

const { Title } = Typography;
const { Content } = Layout;

function CoverLetterPage() {
  const { apiIntegrations } = useContext(ApiIntegrationsContext);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState('');
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [template, setTemplate] = useState('');
  const [jobAd, setJobAd] = useState('');
  const [link, setLink] = useState('');
  const [file, setFile] = useState('');
  const [personal, setPersonal] = useState('');
  const [prompt, setPrompt] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [coverLetters, setCoverLetters] = useState([]);
  const [newCoverLetter, setNewCoverLetter] = useState(null);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleCreateNewCoverLetter = () => {
    const newCoverLetter = {
      id: Date.now().toString(), // Convert id to string
      title: 'New letter',
      template: '',
      jobAd: '',
      generatedText: '',
      link: '',
      file: '',
      personal: '',
      isDraft: true,
    };
    setNewCoverLetter(newCoverLetter);
    setIsCreatingNew(true);
    setIsChanged(true);
    setIsEditing(true);
    setTemplate('');
    setJobAd('');
    setPrompt('');
    setGeneratedText('');
    setTitle('New letter'); // Set title to "New letter"
    setEditingTitle('New letter');
  };

  const handleSaveCoverLetter = () => {
    const coverLetterToSave = newCoverLetter || selectedCoverLetter;
    if (coverLetterToSave) {
      const updatedCoverLetter = {
        ...coverLetterToSave,
        title: editingTitle,
        template,
        jobAd,
        prompt,
        generatedText,
        isDraft: false,
      };
      const coverLetterRef = ref(
        db,
        `coverLetters/${currentUser.uid}/${updatedCoverLetter.id}`,
      );
      update(coverLetterRef, updatedCoverLetter).then(() => {
        setIsChanged(false);
        setIsEditing(false);
        setIsCreatingNew(false);
        setNewCoverLetter(null);
        setSelectedCoverLetter(updatedCoverLetter); // Set selectedCoverLetter to updatedCoverLetter
      });
    }
  };

  const handleDeleteCoverLetter = (coverLetter) => {
    const coverLetterRef = ref(
      db,
      `coverLetters/${currentUser.uid}/${coverLetter.id}`,
    );
    remove(coverLetterRef);
    if (newCoverLetter === coverLetter) {
      setNewCoverLetter(null);
    }
  };

  const handleGenerateCoverLetter = async () => {
    setLoading(true);
    try {
      const response = await generateCoverLetter(
        template,
        jobAd,
        prompt,
        link,
        file,
        personal,
      );
      setGeneratedText(response);
      setLoading(false);
      setIsChanged(true);
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 5000);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectCoverLetter = (coverLetter) => {
    setIsEditing(false);
    setIsCreatingNew(false);
    setNewCoverLetter(null);
    setSelectedCoverLetter(coverLetter);
    setTitle(coverLetter.title);
    setEditingTitle(coverLetter.title); // Set editingTitle to the title of the selected cover letter
    setTemplate(coverLetter.template);
    setJobAd(coverLetter.jobAd);
    setPrompt(coverLetter.prompt);
    setGeneratedText(coverLetter.generatedText);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setIsChanged(true);
  };

  const handleTitleChange = (e) => {
    setEditingTitle(e.target.value);
    setIsChanged(true); // Set isChanged to true when the title is changed
  };

  const handleCoverLetterTitleChange = (coverLetter, newTitle) => {
    const updatedCoverLetter = { ...coverLetter, title: newTitle };
    const coverLetterRef = ref(
      db,
      `coverLetters/${currentUser.uid}/${updatedCoverLetter.id}`,
    );
    set(coverLetterRef, updatedCoverLetter);
    setCoverLetters((prevCoverLetters) =>
      prevCoverLetters.map((cl) =>
        cl.id === coverLetter.id ? { ...cl, title: newTitle } : cl,
      ),
    );
    setSelectedCoverLetter(updatedCoverLetter);
  };

  const indexUrlContent = async (url) => {
    const indexedContent = await getContentFromUrl(url);
    setLink(indexedContent);
  };

  const handleSetLink = (content) => {
    setLink(content);
  };

  useEffect(() => {
    if (selectedCoverLetter) {
      setIsChanged(
        template !== selectedCoverLetter.template ||
          jobAd !== selectedCoverLetter.jobAd ||
          prompt !== selectedCoverLetter.prompt,
      );
    } else {
      setIsChanged(template !== '' || jobAd !== '' || prompt !== '');
    }
  }, [template, jobAd, prompt, selectedCoverLetter]);

  useEffect(() => {
    const coverLettersRef = ref(db, 'coverLetters/' + currentUser.uid);
    onValue(coverLettersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const coverLettersArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setCoverLetters(coverLettersArray);
      } else {
        setCoverLetters([]);
      }
    });
  }, [currentUser.uid]);

  useEffect(() => {
    if (location.state && location.state.coverLetter) {
      handleSelectCoverLetter(location.state.coverLetter);
    } else if (coverLetters.length > 0) {
      handleSelectCoverLetter(coverLetters[coverLetters.length - 1]);
    }
  }, [location.state, coverLetters]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (newCoverLetter && isCreatingNew) {
      setTemplate(newCoverLetter.template);
      setJobAd(newCoverLetter.jobAd);
      setPrompt(newCoverLetter.prompt);
    }
  }, [newCoverLetter, setTemplate, setJobAd, setPrompt, isCreatingNew]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout style={{ overflow: 'hidden' }}>
      <Layout
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {isMobile ? (
          <>
            <div
              style={{
                padding: '12px 8px',
                width: '100%',
                background: '#f5f5f5',
                zIndex: 2,
              }}
            >
              <Button
                onClick={() => setIsDrawerVisible(true)}
                style={{ width: '100%' }}
              >
                View all letters
              </Button>
              <CLSiderDrawer
                coverLetters={coverLetters}
                onSelectCoverLetter={handleSelectCoverLetter}
                onDeleteCoverLetter={handleDeleteCoverLetter}
                onDeleteAll={() => {
                  const coverLettersRef = ref(
                    db,
                    'coverLetters/' + currentUser.uid,
                  );
                  remove(coverLettersRef);
                  setCoverLetters([]);
                }}
                setNewCoverLetter={setNewCoverLetter}
                newCoverLetter={newCoverLetter}
                onCreateNew={handleCreateNewCoverLetter}
                onCoverLetterTitleChange={handleCoverLetterTitleChange}
                selectedCoverLetter={selectedCoverLetter}
                editingTitle={editingTitle}
                setEditingTitle={setEditingTitle}
                isDrawerVisible={isDrawerVisible}
                setIsDrawerVisible={setIsDrawerVisible}
              />
            </div>
          </>
        ) : (
          <CoverLetterSider
            coverLetters={coverLetters}
            onSelectCoverLetter={handleSelectCoverLetter}
            onDeleteCoverLetter={handleDeleteCoverLetter}
            onDeleteAll={() => {
              const coverLettersRef = ref(
                db,
                'coverLetters/' + currentUser.uid,
              );
              remove(coverLettersRef);
              setCoverLetters([]);
            }}
            setNewCoverLetter={setNewCoverLetter}
            newCoverLetter={newCoverLetter}
            onCreateNew={handleCreateNewCoverLetter}
            onCoverLetterTitleChange={handleCoverLetterTitleChange}
            selectedCoverLetter={selectedCoverLetter}
            editingTitle={editingTitle} // Add this line
            setEditingTitle={setEditingTitle} // Add this line
          />
        )}
        <Content
          className="site-layout-background"
          style={{
            margin: 0,
            minHeight: 280,
            width: '100%',
            padding: '0 0 128px 0',
          }}
        >
          {coverLetters.length === 0 && !newCoverLetter ? (
            <div
              style={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                height: 'max-content',
              }}
            >
              <Empty
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                image={
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      marginBottom: 16,
                    }}
                  >
                    <BuildOutlined style={{ fontSize: '64px' }} />
                  </div>
                }
                description={
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography.Title level={2} style={{ marginTop: '12px' }}>
                      Your Generative Agent
                    </Typography.Title>
                    <Typography.Paragraph
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '0px',
                      }}
                    >
                      A powerful text generator that uses DaVinci completion
                      engine along to generate text based on multiple contexts.{' '}
                      <br></br> Just give it the context and let it generate.{' '}
                      <br></br>
                    </Typography.Paragraph>
                    <ul
                      style={{
                        width: 'max-content',
                        textAlign: 'left',
                        margin: '12px 0 20px -16px',
                      }}
                    >
                      <li>Multiple file support </li>
                      <li>Context via web links</li>
                      <li>Unlimited text input</li>
                      <li>Formatting support</li>
                    </ul>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleCreateNewCoverLetter}
                      style={{ width: isMobile ? '100%' : 'auto' }}
                    >
                      Start with your first project
                    </Button>
                  </span>
                }
              />
            </div>
          ) : (
            <>
              <Collapse
                className={
                  generatedText && !isEditing
                    ? 'custom-collapse-collapsed'
                    : 'custom-collapse'
                }
                expandIcon={() => null}
                activeKey={isEditing ? ['1'] : []} // Make it always manually expandable and collapsible
                style={{
                  width: '100%',
                  padding: 0,
                  marginBottom: '20px',
                  borderRadius: 0,
                  border: 'none',
                  boxShadow: 'rgb(228, 228, 228) 0px 0px 10px 4px',
                  zIndex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {generatedText && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        gap: '12px',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexWrap: isMobile ? 'wrap' : 'nowrap',
                        padding: '12px 32px 0px 32px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div style={{ width: isMobile ? '100%' : '180px' }}>
                        <Typography
                          style={{
                            fontSize: '12px',
                            fontWeight: 600,
                            marginBottom: '4px',
                          }}
                        >
                          Letter title
                        </Typography>
                        <Input
                          value={editingTitle}
                          onChange={handleTitleChange}
                          onPressEnter={(e) => e.preventDefault()} // Prevent submission on pressing Enter
                          size="medium"
                          placeholder="Enter a unique title"
                          style={{
                            maxWidth: '240px',
                            minWidth: isMobile ? '100%' : '180px',
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => setIsEditing(!isEditing)}
                      >
                        <Button
                          type={isEditing ? 'text' : 'primary'}
                          size="medium"
                          style={{ width: isMobile ? '100%' : 'max-content' }}
                        >
                          {isEditing ? 'Hide context fields' : 'Edit context'}
                          {isEditing ? (
                            <CaretUpOutlined />
                          ) : (
                            <CaretDownOutlined />
                          )}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '16px',
                          width: isMobile ? '100%' : 'auto',
                        }}
                      >
                        <Button
                          type="default"
                          onClick={handleSaveCoverLetter}
                          icon={<UploadOutlined />}
                          style={{ width: isMobile ? '100%' : 'auto' }}
                        >
                          Upload
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSaveCoverLetter}
                          disabled={!isChanged && !isTitleChanged} // Modify this line
                          icon={<SaveOutlined />}
                          style={{ width: isMobile ? '100%' : 'auto' }}
                        >
                          Save letter
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                <Collapse.Panel
                  key="1"
                  style={{
                    borderRadius: 0,
                    border: 'none',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <CoverLetterGenerator
                    template={template}
                    setTemplate={setTemplate}
                    jobAd={jobAd}
                    setJobAd={setJobAd}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    link={link}
                    setLink={setLink}
                    file={file}
                    setFile={setFile}
                    personal={personal}
                    setPersonal={setPersonal}
                    handleGenerateCoverLetter={handleGenerateCoverLetter}
                    newCoverLetter={newCoverLetter}
                    onSelectCoverLetter={handleSelectCoverLetter}
                    handleInputChange={handleInputChange}
                    isCreatingNew={isCreatingNew}
                    isChanged={isChanged}
                    loading={loading}
                    indexUrlContent={indexUrlContent}
                    handleSetLink={handleSetLink}
                  />
                </Collapse.Panel>
              </Collapse>
              <Content
                style={{
                  padding: isMediumScreen
                    ? '0 12px'
                    : isMobile
                    ? '0 16px'
                    : isMobile
                    ? '0 16px '
                    : '0 48px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ maxWidth: '1200px', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: generatedText
                        ? 'space-between'
                        : 'center',
                      alignItems: 'flex-end',
                      gap: '12px',
                      paddingBottom: '12px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {loading ? (
                      <>
                        <div style={{ width: '278px' }}></div>
                        <Alert
                          message="Your letter is being generated. It may take a few seconds. Please wait."
                          type="warning"
                          style={{
                            alignItems: 'baseline',
                            fontSize: '12px',
                            height: 'min-content',
                          }}
                        />
                        <div style={{ width: '278px' }}></div>
                      </>
                    ) : showSuccessAlert ? (
                      <Alert
                        message={
                          <>
                            Your letter has been generated successfully.
                            <br />
                            Don't forget to save.
                          </>
                        }
                        style={{
                          alignItems: 'baseline',
                          fontSize: '12px',
                          height: 'min-content',
                        }}
                        type="success"
                        showIcon
                        closable
                      />
                    ) : null}
                    <div style={{ width: '278px' }}></div>
                  </div>

                  <div
                    style={{
                      position: 'relative',
                      border: '1px solid #d9d9d9',
                      borderRadius: '8px',

                      padding: '12px',
                      minHeight: '32px',
                      lineHeight: '1.5715',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {generatedText && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              margin: '0 0 24px 0',
                              padding: '12px 0 0 20px',
                              fontWeight: 300,
                              fontSize: '14px',
                              fontStyle: 'italic',
                              textAlign: 'center',
                              color: '#bbbbbb',
                            }}
                          >
                            - {title} -
                          </Typography>
                        </div>
                      )}
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Button
                          type="default"
                          size="small"
                          style={{ fontSize: '12px', padding: '0 6px' }}
                          onClick={() => {
                            navigator.clipboard.writeText(generatedText);
                          }}
                        >
                          <CopyOutlined />
                        </Button>
                        <Button
                          type="default"
                          size="small"
                          style={{ fontSize: '12px', padding: '0 6px' }}
                        >
                          <EditOutlined />
                        </Button>
                      </div>
                    </div>
                    <pre
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        maxWidth: '72ch',
                        marginTop: 0,
                      }}
                    >
                      {generatedText ? (
                        <div>{generatedText}</div>
                      ) : (
                        <Empty
                          description={
                            <span>
                              <Typography.Title
                                level={5}
                                style={{ marginTop: '12px' }}
                              >
                                Your AI generated letter will appear here
                              </Typography.Title>
                              <Typography.Paragraph>
                                Just give it the context and let it generate
                              </Typography.Paragraph>
                            </span>
                          }
                        />
                      )}
                    </pre>
                  </div>
                </div>
              </Content>
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default CoverLetterPage;
