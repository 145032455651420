// src/components/scraper/SendToN8n.js
import React, { useState, useEffect } from 'react';
import {
  Input,
  Row,
  Col,
  Button,
  AutoComplete,
  Spin,
  Alert,
  Radio,
  Typography,
  Card,
  Select,
  Tag,
} from 'antd';
import { app, db, ref, set, callN8nWorkflow } from '../../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import { LinkOutlined } from '@ant-design/icons';

const getNextId = (id) => {
  if (!id) return 'A';
  const lastChar = id.slice(-1);
  if (lastChar !== 'Z') {
    return id.slice(0, -1) + String.fromCharCode(lastChar.charCodeAt() + 1);
  }
  return id + 'A';
};

const { Option } = Select;

const SendToN8n = ({ sessionId, setSessionId, user }) => {
  useEffect(() => {
    setSessionId(uuidv4());
    set(ref(db, `extractedData/${sessionId}`), {});
  }, [setSessionId]);

  const [url, setUrl] = useState('');
  const [cssSelectors, setCssSelectors] = useState([]);
  const [format, setFormat] = useState('plain');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [firebaseError, setFirebaseError] = useState(null);

  const [selectedSelectors, setSelectedSelectors] = useState([]); // Add state for selected selectors

  const selectBefore = (
    <Select defaultValue="http://">
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  const handleSelectorChange = (value) => {
    // Add handler for selector change
    setSelectedSelectors(value);
  };

  const clearError = () => {
    setErrorMessage(null);
    const dbRef = ref(db, 'errors');
    set(dbRef, null);
  };

  const sendData = async () => {
    setLoading(true);
    clearError();
    let id = '';
    try {
      for (let i = 0; i < cssSelectors.length; i++) {
        id = getNextId(id);
        const params = {
          url: url,
          cssSelector: cssSelectors[i],
          format: format,
          id: id,
          uid: user.uid,
          sessionId: sessionId,
        };
        await callN8nWorkflow(params)
          .then((response) => {
            if (response.data.message !== 'Workflow was started') {
              set(
                ref(db, `extractedData/${user.uid}/${sessionId}/${id}`),
                response.data,
              )
                .then(() => {
                  console.log(
                    'Response data saved to Firebase successfully:',
                    response.data,
                  );
                })
                .catch((error) => {
                  console.error('Error sending data to the server:', error);
                  setFirebaseError(
                    'Error sending data to the server: ' + error.message,
                  );
                });
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error sending data to the server:', error);
            setErrorMessage(
              'Error sending data to the server: ' + error.message,
            );
            setLoading(false);
          });
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } catch (error) {
      console.error('Error in sendData function:', error);
      setErrorMessage('Error in sendData function: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firebaseError) {
      setErrorMessage(firebaseError);
    }
  }, [firebaseError]);

  return (
    <Card style={{ padding: 8 }}>
      <Row gutter={[16, 16]} style={{ margin: 0 }}>
        {errorMessage && (
          <Col span={24}>
            <Alert message={errorMessage} type="error" />
          </Col>
        )}
        <Col span={24}>
          <Input
            addonBefore={selectBefore}
            defaultValue="mysite"
            placeholder="Enter URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            style={{ width: '100%', justifyContent: 'center' }}
            prefix={<LinkOutlined />}
          />
        </Col>
        <Col span={24}>
          <Select // Add Select component for CSS selectors
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Add CSS selectors"
            value={selectedSelectors}
            onChange={handleSelectorChange}
          />
          <Button
            onClick={sendData}
            type="primary"
            style={{ width: '100%', marginTop: 16 }}
            loading={loading}
            disabled={!url || selectedSelectors.length === 0} // Disable button unless there is url input and at least one css value entered
          >
            Start Scraping
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default SendToN8n;
