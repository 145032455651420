import React, { useState, useEffect } from 'react';
import {
  Card,
  Switch,
  Alert,
  Button,
  Radio,
  Badge,
  Typography,
  Tag,
  Divider,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
  CheckCircleFilled,
  SettingOutlined,
  LockFilled,
  LockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from '../firebase/firebase';
import { db } from '../firebase/firebase';
import { onValue, ref } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { redirectToCustomerPortal } from '../firebase/firebase';
import { getPriceDetails } from '../firebase/firebase';
function CardsInAGrid({
  title,
  description,
  version,
  alternativesLink,
  apiIntegrations,
  setApiIntegrations,
  priceId,
  fetchCheckoutSession,
  subscribeButton = false,
}) {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isEnabled, setIsEnabled] = useState(isSubscribed);
  const isBeta = parseFloat(version) < 1.0;
  const [isReadMore, setIsReadMore] = useState(false);
  const [price, setPrice] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState('monthly');

  useEffect(() => {
    const fetchPriceDetails = async () => {
      const { data: price } = await getPriceDetails({
        monthlyPriceId: 'price_1OJHyEBWY92W6DAF8cg5i47h',
        yearlyPriceId: 'price_1OJHyEBWY92W6DAF8cg5i47h',
      });
      setPrice(price);
    };
    fetchPriceDetails();
  }, []);

  useEffect(() => {
    const fetchSubscription = async () => {
      onValue(ref(db, `users/${userId}/subscriptions/${title}`), (snapshot) => {
        const subscription = snapshot.val();
        setIsSubscribed(!!subscription);
      });
    };
    fetchSubscription();
  }, [userId, title]);

  useEffect(() => {
    setIsEnabled(isSubscribed);
  }, [isSubscribed]);

  const handleSubscribeClick = async () => {
    const stripe = await stripePromise;
    const { data } = await createCheckoutSession({
      priceId:
        subscriptionType === 'monthly' ? price.monthly.id : price.yearly.id,
      feature: title,
    });

    const sessionId = data.sessionId;

    await stripe.redirectToCheckout({
      sessionId: sessionId,
      client_reference_id: auth.currentUser.uid,
      metadata: {
        feature: title,
      },
    });
  };

  const handleManageClick = async () => {
    const { data: url } = await redirectToCustomerPortal();
    window.location.assign(url);
  };

  const handleToggle = () => {
    setApiIntegrations((prevApiIntegrations) => ({
      ...prevApiIntegrations,
      [title]: {
        ...prevApiIntegrations[title],
        isEnabled: !prevApiIntegrations[title]?.isEnabled || false,
      },
    }));
  };

  useEffect(() => {
    setIsEnabled(apiIntegrations[title]?.isEnabled || false);
  }, [apiIntegrations, title]);

  return (
    <Card
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h3 style={{ margin: 0, paddingRight: '16px' }}>{title}</h3>
              <p
                style={{ fontSize: '12px', color: 'grey', margin: '8px 0 0 0' }}
              >
                {version}
                {isBeta && (
                  <Badge
                    count="Beta"
                    style={{
                      backgroundColor: '#f8fff4',
                      border: '1px solid rgb(82, 196, 26)',
                      color: 'rgb(82, 196, 26)',
                      fontSize: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '7px',
                      height: '18px',
                      margin: '0 0 4px 8px',
                    }}
                  />
                )}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {isSubscribed ? (
                <>
                  <Button
                    type="default"
                    size="small"
                    onClick={handleManageClick}
                    style={{ padding: '0 5px' }}
                  >
                    <SettingOutlined
                      style={{ color: 'grey', fontSize: '13px' }}
                    />
                  </Button>
                  <Switch
                    checked={isEnabled}
                    onChange={handleToggle}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={
                      <CloseOutlined style={{ color: 'lightgrey' }} />
                    }
                  />
                </>
              ) : (
                <Tag
                  style={{
                    fontSize: '14px',
                    padding: '4px 8px',
                    margin: 0,
                    backgroundColor: '#fff9ed',
                    color: '#f0ac24',
                    borderColor: 'transparent',
                  }}
                >
                  <LockFilled />
                </Tag>
              )}
            </div>
          </div>
          <div>
            <p
              style={{
                fontSize: '12px',
                margin: 0,
                display: isReadMore ? 'block' : '-webkit-box',
              }}
            >
              {description}
            </p>
            <div style={{ margin: '8px 0 8px 0' }}>
              <Link
                to={alternativesLink}
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                }}
              >
                More about the app
                <ExportOutlined style={{ paddingLeft: 4 }} />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Divider style={{ margin: '8px 0' }} />
          <div style={{ margin: 0, paddingTop: '12px' }}>
            {isSubscribed && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                    paddingBottom: '8px',
                  }}
                >
                  <CheckCircleFilled
                    style={{ fontSize: '14px', color: 'rgb(82, 196, 26)' }}
                  />
                  <p style={{ fontSize: '12px', fontWeight: 500, margin: 0 }}>
                    Subscribed
                  </p>
                </div>
                <p style={{ fontSize: '10px', fontWeight: 300, margin: 0 }}>
                  Yearly subscription, started on 05.12.23 <br></br>Renews each
                  month
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    gap: '8px',
                    paddingTop: '12px',
                  }}
                >
                  {isSubscribed && (
                    <>
                      <Button
                        type="link"
                        block
                        style={{
                          width: 'max-content',
                          padding: '0',
                          fontSize: '12px',
                          height: 'max-content',
                        }}
                        onClick={handleManageClick}
                      >
                        Manage
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          {subscribeButton && !isSubscribed && (
            <>
              {price && (
                <div style={{ margin: '0 0 16px' }}>
                  <Radio.Group
                    className="radio-group"
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    value={subscriptionType}
                    buttonStyle="solid"
                    style={{ margin: '12px 0 8px 0', display: 'flex' }}
                  >
                    <Radio.Button
                      value={'monthly'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 0 8px',
                      }}
                    >
                      Monthly
                      <Typography
                        id="radio-price"
                        style={{ margin: 0, fontSize: '14px' }}
                      >
                        {subscriptionType === 'monthly'
                          ? price.monthly.unit_amount
                          : price.yearly.unit_amount}
                        €
                      </Typography>
                    </Radio.Button>
                    <Radio.Button
                      value={'yearly'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 0 8px',
                      }}
                    >
                      Yearly
                      <Typography
                        id="radio-price"
                        style={{ margin: 0, fontSize: '14px' }}
                      >
                        {subscriptionType === 'monthly'
                          ? price.monthly.unit_amount
                          : price.yearly.unit_amount}
                        €
                      </Typography>
                    </Radio.Button>
                    <Radio.Button
                      value={'bundle'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 0 8px',
                      }}
                    >
                      Big Pack
                      <Typography
                        id="radio-price"
                        style={{ margin: 0, fontSize: '14px' }}
                      >
                        {subscriptionType === 'monthly'
                          ? price.monthly.unit_amount
                          : price.yearly.unit_amount}
                        €
                      </Typography>
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )}
              <Button
                type="primary"
                size="large"
                block
                onClick={handleSubscribeClick}
              >
                <LockOutlined />
                Unlock
              </Button>
              <Button
                type="link"
                size="small"
                style={{
                  padding: '16px 0 0 0',
                  width: '100%',
                  fontSize: '12px',
                  height: 'max-content',
                }}
              >
                View all prices
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}

export default CardsInAGrid;
