// src/firebase/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, remove, set, onValue } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCLvGfGBPmhoKqgPX9d7T7jExBhL-nTIaE',
  authDomain: 'webeasy-ab446.firebaseapp.com',
  projectId: 'webeasy-ab446',
  storageBucket: 'webeasy-ab446.appspot.com',
  messagingSenderId: '976247077020',
  appId: '1:976247077020:web:3ad8848517dc69c95b1559',
  measurementId: 'G-HCTLD87EKP',
  databaseURL:
    'https://webeasy-ab446-default-rtdb.europe-west1.firebasedatabase.app/',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getDatabase(app);
export const functions = getFunctions(app);

// Export database operations
export { ref, set, onValue };

// Initialize callable function

export const getPriceDetails = httpsCallable(functions, 'getPriceDetails');
export const createCheckoutSession = httpsCallable(
  functions,
  'createCheckoutSession',
);
export const retrieveCheckoutSession = httpsCallable(
  functions,
  'retrieveCheckoutSession',
);
export const redirectToCustomerPortal = httpsCallable(
  functions,
  'redirectToCustomerPortal',
);
export const callN8nWorkflow = httpsCallable(functions, 'callN8nWorkflow');

export function deleteCoverLetter(userId, coverLetterId) {
  const coverLetterRef = ref(db, `coverLetters/${userId}/${coverLetterId}`);
  remove(coverLetterRef);
}
