// src/components/scraper/Output.js
import React, { useEffect, useState } from 'react';
import { db, ref, set, onValue, off } from '../../firebase/firebase';
import {
  Card,
  Typography,
  Alert,
  Button,
  Radio,
  Table,
  Space,
  Row,
  Col,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const Output = ({ db, onData, setLoading, sessionId }) => {
  const [data, setData] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [format, setFormat] = useState('plain');
  const [editing, setEditing] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [error, setError] = useState('');
  const [latestError, setLatestError] = useState(null);

  useEffect(() => {
    const dbRef = ref(db, `n8nOutputs/${sessionId}`);

    const handleNewData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setData(data);
        onData(data);
        setLoading(false);
      }
    };

    const unsubscribe = onValue(dbRef, handleNewData);

    return () => {
      unsubscribe();
    };
  }, [db, sessionId]);

  const headers = Object.keys(data);
  const longestArrayLength = Math.max(
    ...Object.values(data).map((arr) => arr.length),
  );
  const displayLength = showAll
    ? longestArrayLength
    : Math.min(20, longestArrayLength);

  if (Object.keys(data).length === 0) {
    return null;
  }
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={7} lg={5} xl={5}>
        <Card style={{ padding: '20px' }}>
          <Typography.Title level={5} style={{ margin: '0 0 8px 0' }}>
            Output
          </Typography.Title>

          {latestError ? (
            <Alert
              message="There's a problem with the scraper."
              description={`Error log: ${latestError}`}
              type="error"
              showIcon
            />
          ) : (
            <>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Radio.Group
                  value={format}
                  size="small"
                  onChange={(event) => {
                    setFormat(event.target.value);
                  }}
                  aria-label="view mode"
                >
                  <Radio.Button value="plain" aria-label="plain text">
                    Plain text
                  </Radio.Button>
                  <Radio.Button value="table" aria-label="table">
                    Table
                  </Radio.Button>
                </Radio.Group>
                <Space direction="horizontal" size="middle">
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const dbRef = ref(db, 'extractedData');
                      set(dbRef, {});
                      setData({});
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    icon={editing ? <></> : <EditOutlined />}
                    onClick={() => setEditing(!editing)}
                  >
                    {editing ? 'Cancel' : 'Edit'}
                  </Button>
                </Space>
              </Space>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Output;
