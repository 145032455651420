// src/pages/RegisterPage.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { Button, Input, Form, Alert, Spin, Typography } from 'antd';
import { LockOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import ContentWrapper from '../components/ContentWrapper';
import { createUserWithEmailAndPassword } from 'firebase/auth';

function RegisterPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);

  const register = ({ email, password }) => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLoading(false);
        navigate('/my-tools/chatbot');
      })
      .catch((error) => {
        setLoading(false);
        setRegisterError('Failed to register. Try again.');
        console.error('Error registering', error);
      });
  };

  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '48px',
          maxWidth: '320px',
          margin: '0px auto',
          height: '80vh',
        }}
      >
        <Typography.Title
          level={1}
          style={{ textAlign: 'center', margin: '24px 0 48px' }}
        >
          Register
        </Typography.Title>
        {registerError && (
          <Alert
            message={registerError}
            type="error"
            style={{ marginBottom: '24px' }}
          />
        )}
        {loading && (
          <Alert
            message="Creating your account"
            type="success"
            icon={<Spin />}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form
          onFinish={register}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <Input
              size="large"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="Phone Number"
            />
          </Form.Item>
          <Form.Item
            name="job"
            rules={[{ required: true, message: 'Please input your job!' }]}
          >
            <Input size="large" placeholder="Job" />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true, message: 'Please input your role!' }]}
          >
            <Input
              size="large"
              placeholder="Role"
              style={{ marginBottom: '16px' }}
            />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Register
          </Button>
          <p style={{ margin: '48px 0', textAlign: 'center' }}>
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </Form>
      </div>
    </ContentWrapper>
  );
}

export default RegisterPage;
