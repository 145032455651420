// src/RoutesWithAuth.js
import React, { useContext, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from './AuthContext';
import HomePage from './pages/HomePage';
import MainFeaturePage from './pages/MainFeaturePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CommunityHubPage from './pages/CommunityHubPage';
import AboutPage from './pages/AboutPage';
import ChatbotPage from './pages/ChatbotPage';
import AccountSettings from './pages/AccountSettings';
import CoverLetterPage from './pages/CoverLetter';
import ScraperPage from './pages/ScraperPage';
import SettingsPage from './pages/SettingsPage';
import AnalyticsPage from './pages/AnalyticsPage';
import IntegrationsPage from './pages/Apps';
import BlogPost from './components/BlogPost';
import { ApiIntegrationsContext } from './ApiIntegrationsContext';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

function RoutesWithAuth(apiIntegrations, feature) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (
        !user &&
        location.pathname !== '/register' &&
        location.pathname.startsWith('/my-tools')
      ) {
        navigate('/login', { state: { fromMainFeature: true } });
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {currentUser ? (
        <>
          <Route path="/my-tools" element={<MainFeaturePage />}>
            <Route
              path="/my-tools/chatbot"
              element={
                apiIntegrations['Chatbot']?.isEnabled ? (
                  <ChatbotPage />
                ) : (
                  <Navigate to="/my-tools/apps" />
                )
              }
            />
            <Route
              path="/my-tools/cover-letter"
              element={
                apiIntegrations['Generative Agent']?.isEnabled ? (
                  <CoverLetterPage />
                ) : (
                  <Navigate to="/my-tools/apps" />
                )
              }
            />
            <Route
              path="/my-tools/scraper"
              element={
                apiIntegrations['Web Scraper']?.isEnabled ? (
                  <ScraperPage />
                ) : (
                  <Navigate to="/my-tools/apps" />
                )
              }
            />
            <Route path="cover-letter" element={<CoverLetterPage />} />
            <Route path="scraper" element={<ScraperPage />} />
            <Route path="settings" element={<SettingsPage />} />{' '}
            {/* Move SettingsPage route here */}
            <Route path="apps" element={<IntegrationsPage />} />{' '}
            {/* Move IntegrationsPage route here */}
            <Route path="analytics" element={<AnalyticsPage />} />{' '}
            {/* Move AnalyticsPage route here */}
          </Route>
        </>
      ) : (
        <Route path="/my-tools/*" element={<Navigate to="/login" replace />} />
      )}
      <Route
        path="/login"
        element={
          currentUser ? (
            <Navigate to="/my-tools/chatbot" replace />
          ) : (
            <LoginPage />
          )
        }
      />
      <Route
        path="/register"
        element={
          currentUser ? (
            <Navigate to="/my-tools/chatbot" replace />
          ) : (
            <RegisterPage />
          )
        }
      />
      <Route path="/account-settings" element={<AccountSettings />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/apps" element={<IntegrationsPage />} />
      <Route path="/analytics" element={<AnalyticsPage />} />
      <Route path="/community-hub" element={<CommunityHubPage />} />
      <Route path="/community-hub/:id" element={<BlogPost />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
    </Routes>
  );
}
export default RoutesWithAuth;
