// src/components/Sider.js
import React, { useState, useEffect, useContext } from 'react';
import { Layout, Menu, Affix, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  CommentOutlined,
  SettingOutlined,
  LineChartOutlined,
  BuildOutlined,
  KeyOutlined,
  RightOutlined,
  LeftOutlined,
  BulbOutlined,
} from '@ant-design/icons';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import SiderTabsMobile from './SiderTabsMobile';
import ApiIntegrationsContext from '../ApiIntegrationsContext';

const { Sider } = Layout;

function AppSider({ isOtherSiderPresent }) {
  const { apiIntegrations } = useContext(ApiIntegrationsContext);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 900px) and (max-width: 1199px)',
  });
  const location = useLocation();
  const selectedKey = location.pathname.split('/').pop() || 'chatbot';
  const [collapsed, setCollapsed] = useState(isOtherSiderPresent);
  const isFeatureEnabled = apiIntegrations.isEnabled;

  const isExtraLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  useEffect(() => {
    if (!isOtherSiderPresent) {
      if (isExtraLargeScreen) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    }
  }, [
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
    isOtherSiderPresent,
  ]);

  useEffect(() => {
    setCollapsed(isOtherSiderPresent);
  }, [isOtherSiderPresent]);

  return isSmallScreen ? (
    <SiderTabsMobile />
  ) : (
    <Affix offsetTop={64}>
      <Sider
        style={{
          height: '96vh',
          borderRight: '0.5px solid #d5d5d5',
          marginRight: '1px',
        }}
        width={collapsed ? 72 : 200}
      >
        <Menu
          mode="inline"
          collapsed={collapsed}
          selectedKeys={[selectedKey]}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              position: 'absolute',
              top: '40%',
              right: '-50%',
              zIndex: '10',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                width: 'max-content',
                textAlign: collapsed ? 'center' : 'right',
                padding: '0 1.8px',
                border: '0.5px solid #d5d5d5',
                height: '34px',
                fontSize: '12px',
                background: 'white',
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = '#f2f2f2')
              }
              onMouseLeave={(e) => (e.currentTarget.style.background = 'white')}
            >
              {collapsed ? (
                <RightOutlined style={{ color: 'grey' }} />
              ) : (
                <LeftOutlined style={{ color: 'grey' }} />
              )}
            </Button>
          </div>

          <Menu.ItemGroup
            key="g1"
            title={collapsed ? '' : 'Control panel'}
            style={{ paddingTop: '12px' }}
          />

          {apiIntegrations['Chatbot']?.isEnabled && (
            <Menu.Item key="chatbot" icon={<CommentOutlined />}>
              <Link to="/my-tools/chatbot">{collapsed ? '' : 'Chatbot'}</Link>
            </Menu.Item>
          )}
          {apiIntegrations['Generative Agent']?.isEnabled && (
            <Menu.Item key="cover-letter" icon={<BuildOutlined />}>
              <Link to="/my-tools/cover-letter">
                {collapsed ? '' : 'Generative Agent'}
              </Link>
            </Menu.Item>
          )}
          {apiIntegrations['Web Scraper']?.isEnabled && (
            <Menu.Item key="scraper" icon={<KeyOutlined />}>
              <Link to="/my-tools/scraper">
                {collapsed ? '' : 'Web Scraper'}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="apps" icon={<ApiOutlined />}>
            <Link to="/my-tools/apps">{collapsed ? '' : 'Apps'}</Link>
          </Menu.Item>
          <Menu.Item key="settings" icon={<BulbOutlined />}>
            <Link to="/community-hub">{collapsed ? '' : 'Community Hub'}</Link>
          </Menu.Item>
          <Menu.Item key="analytics" icon={<LineChartOutlined />}>
            <Link to="/my-tools/analytics">{collapsed ? '' : 'Analytics'}</Link>
          </Menu.Item>
          <Menu.Item key="settings" icon={<SettingOutlined />}>
            <Link to="/my-tools/settings">{collapsed ? '' : 'Settings'}</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </Affix>
  );
}

export default AppSider;
