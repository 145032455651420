// src/components/CoverLetterSiderDrawer.js
import React from 'react';
import { Drawer, Typography } from 'antd';
import { BuildOutlined } from '@ant-design/icons';
import CoverLetterSider from './CoverLetterSider';

function CoverLetterSiderDrawer({
  visible,
  onClose,
  isDrawerVisible,
  setIsDrawerVisible,
  ...props
}) {
  return (
    <Drawer
      placement="left"
      closable={true}
      onClose={() => setIsDrawerVisible(false)}
      visible={isDrawerVisible}
      width={200}
      bodyStyle={{ padding: '24px 8px 0 8px' }}
      headerStyle={{ padding: '12px 0 12px 0' }}
    >
      <CoverLetterSider {...props} isDrawer={true} />
    </Drawer>
  );
}

export default CoverLetterSiderDrawer;
