// src/components/BreadcrumbWrapper.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { useMediaQuery } from 'react-responsive';

function BreadcrumbWrapper() {
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const location = useLocation();

  if (location.pathname.startsWith('/my-tools')) {
    return null;
  }

  return (
    <div style={{ padding: isLargeScreen ? '0 24px' : '0 24px', zIndex: 100 }}>
      <Breadcrumb pathname={location.pathname} />
    </div>
  );
}

export default BreadcrumbWrapper;
