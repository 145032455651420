// src/components/UserDropdown.js
import React from 'react';
import { Avatar, Dropdown, Menu, Modal } from 'antd';
import {
  UserOutlined,
  CaretDownOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const { confirm } = Modal;

function UserDropdown({ user }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will be redirected to the login page.',
      onOk() {
        const auth = getAuth();
        signOut(auth).then(() => {
          navigate('/login');
        });
      },
      onCancel() {
        // User clicked the cancel button, do nothing
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => navigate('/account-settings')}>
        Account Settings
      </Menu.Item>
      <Menu.Item key="1" onClick={handleLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: '100%',
        }}
      >
        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />}>
          {user && user.firstName && user.lastName
            ? `${user.firstName[0]}${user.lastName[0]}`
            : 'U'}
        </Avatar>
        <CaretDownOutlined style={{ marginLeft: '8px', color: 'white' }} />
      </div>
    </Dropdown>
  );
}

export default UserDropdown;
