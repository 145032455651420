// src/components/MessagingScreen.js
import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Typography, List, Avatar, Alert, Spin } from 'antd';
import { UserOutlined, RobotOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

dayjs.extend(relativeTime);

const { Text } = Typography;

function MessagingScreen({ messages, isLoading }) {
  const messagesEndRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div
      ref={messagesEndRef}
      style={{
        height: 'calc(90vh - 200px)',
        overflowY: 'scroll',
        padding: isMobile ? '0px' : '24px',
      }}
    >
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={messages || []}
        renderItem={(item) => (
          <List.Item
            className={item.author === 'User' ? 'user-message' : 'bot-message'}
            key={messages.length}
            style={{
              flexDirection: item.author === 'User' ? 'row-reverse' : 'row',
              marginBottom: '10px',
              maxWidth: '100%',
              justifyContent:
                item.author === 'User' ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: item.author === 'User' ? 'flex-end' : 'flex-start',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: item.author === 'User' ? 'row-reverse' : 'row',
                  width: '100%',
                  alignItems: 'flex-start',
                  gap: '8px',
                }}
              >
                <Avatar
                  icon={
                    item.author === 'User' ? (
                      <UserOutlined />
                    ) : (
                      <RobotOutlined />
                    )
                  }
                  style={{ width: '32px', height: '32px' }}
                />
                <div
                  style={{
                    padding: '12px',
                    borderRadius: '8px',
                    display: 'flex',
                    gap: '4px',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    background: item.author === 'User' ? '#d3e5fd' : 'white',
                  }}
                >
                  <Text style={{ maxWidth: '72ch' }}>
                    {typeof item.content === 'string'
                      ? item.content
                      : JSON.stringify(item.content)}
                  </Text>
                  <Text style={{ fontSize: '10px' }} type="secondary">
                    {dayjs(item.datetime).fromNow()}
                  </Text>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      >
        {isLoading && (
          <Alert
            message="It may take a while to generate a response to your message. Please wait."
            type="success"
            icon={<Spin size="small" />}
          />
        )}
        <div ref={messagesEndRef} />
      </List>
    </div>
  );
}

export default MessagingScreen;
