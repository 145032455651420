import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ApiIntegrationsContext from '../ApiIntegrationsContext';
import { Row, Col } from 'antd';
import Card from '../components/Card';
import { SettingOutlined } from '@ant-design/icons';
import PageHeader from '../components/PageHeader';
import { loadStripe } from '@stripe/stripe-js';
import { db } from '../firebase/firebase';
import { onValue, ref, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { createCheckoutSession } from '../firebase/firebase';

const correctCodes = {
  AdvancedChatbot: 'cbt0001',
  GenerativeAgent: 'cov0001',
  WebScraper: 'scr0001',
};

function IntegrationsPage(subscribeButton = true) {
  const { apiIntegrations, setApiIntegrations } = useContext(
    ApiIntegrationsContext,
  );
  const location = useLocation();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const [feature, setFeature] = useState(
    new URLSearchParams(location.search).get('feature'),
  );
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const fetchCheckoutSession = async () => {
    const sessionId = localStorage.getItem('checkoutSessionId');
    if (sessionId) {
      const { data: checkoutSession } = await createCheckoutSession({
        sessionId,
      });
      if (checkoutSession && checkoutSession.payment_status === 'paid') {
        // The subscription was successful, update local state
        setApiIntegrations((prev) => ({
          ...prev,
          [checkoutSession.metadata.feature]: {
            ...prev[checkoutSession.metadata.feature],
            isEnabled: true,
          },
        }));
      }
      // Clear the checkout session ID from local storage
      localStorage.removeItem('checkoutSessionId');
    } else {
      console.error('No sessionId found in local storage');
    }
  };

  useEffect(() => {
    setFeature(new URLSearchParams(location.search).get('feature'));
  }, [location]);

  useEffect(() => {
    // Read the subscription status from Firebase
    onValue(ref(db, `users/${userId}/subscriptions`), (snapshot) => {
      const subscriptions = snapshot.val();
      if (subscriptions) {
        setApiIntegrations((prev) => ({
          ...prev,
          ...Object.keys(subscriptions).reduce((acc, feature) => {
            acc[feature] = {
              ...prev[feature],
              isEnabled: subscriptions[feature],
            };
            return acc;
          }, {}),
        }));
      }
    });
  }, [feature]);

  return (
    <>
      <PageHeader
        title="Apps"
        description="Manage and configure your apps, subscriptions and API integrations."
        showButton={true}
        buttonText="Manage Subscriptions"
        buttonIcon={<SettingOutlined />}
        iconPosition="left"
        modalContent={<div>Modal content goes here</div>}
        fetchCheckoutSession={fetchCheckoutSession}
      />
      <Row gutter={[16, 16]} justify="start" id="apps-grid">
        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
          <Card
            title="Chatbot"
            description="Special chatbot with advanced capabilities powered by GPT-4, which you can fine tune anytime with ease without code."
            version="0.0.6"
            alternativesLink={'#'}
            apiIntegrations={apiIntegrations}
            setApiIntegrations={setApiIntegrations}
            subscribeButton={subscribeButton}
            correctCode={correctCodes.Chatbot}
            priceId="price_1OJHyEBWY92W6DAF8cg5i47h"
            fetchCheckoutSession={fetchCheckoutSession}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
          <Card
            title="Generative Agent"
            description="Powerful text generator that uses DaVinci completion engine along other APIs to generate text based on multiple contexts."
            version="0.1.2"
            alternativesLink={'#'}
            apiIntegrations={apiIntegrations}
            setApiIntegrations={setApiIntegrations}
            subscribeButton={subscribeButton}
            correctCode={correctCodes.CoverLetter}
            priceId="price_1OJHyEBWY92W6DAF8cg5i47h"
            fetchCheckoutSession={fetchCheckoutSession}
          ></Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
          <Card
            title="Web Scraper"
            description="Easy scraper that allows multiple scraping at once through a simple API call as well as formating options. Edit, export, share."
            version="0.0.2"
            alternativesLink={'#'}
            apiIntegrations={apiIntegrations}
            setApiIntegrations={setApiIntegrations}
            subscribeButton={subscribeButton}
            correctCode={correctCodes.Scraper}
            priceId="price_1OJHyEBWY92W6DAF8cg5i47h"
            fetchCheckoutSession={fetchCheckoutSession}
          ></Card>
        </Col>
      </Row>
    </>
  );
}

export default IntegrationsPage;
