// src/pages/SettingsPage.js
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import PageHeader from '../components/PageHeader';

function SettingsPage() {
  return (
    <PageHeader
      title="Settings"
      description="Description"
      showButton={true}
      buttonText="Add new manually"
      buttonIcon={<PlusOutlined />}
      iconPosition="left"
      modalContent={<div>Modal content goes here</div>}
    />
  );
}

export default SettingsPage;
