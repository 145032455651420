// src/components/Breadcrumb.js
import React from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const pathNameToTitle = {
  'my-tools': 'My tools',
  'privacy-policy': 'Privacy Policy',
  'terms-of-service': 'Terms of Service',
  'community-hub': 'Community Hub',
  about: 'About',
  analytics: 'Analytics',
  settings: 'Settings',
  chatbot: 'Chatbot',
  'account-settings': 'Account Settings',
  apps: 'Apps',
  scraper: 'Web Scraper',
  'cover-letter': 'Generative Agent',
  // Dynamically import blog post titles
  ...(() => {
    const context = require.context('../pages/articles', false, /\.js$/);
    return context.keys().reduce((acc, key) => {
      const postId = key.replace('./', '').replace('.js', '');
      const postModule = context(key);
      acc[postId] = postModule.default.title;
      return acc;
    }, {});
  })(),
};

function BreadcrumbComponent({ pathname, selectedThread }) {
  const pathSnippets = pathname.split('/').filter((i) => i);

  if (
    pathSnippets[0] === 'login' ||
    pathSnippets[0] === 'register' ||
    pathSnippets.length === 0
  ) {
    return null;
  }

  const breadcrumbItems = pathSnippets.map((snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    let breadcrumbName = pathNameToTitle[snippet] || snippet;
    let breadcrumbIcon = null;

    // Check if this is the last item in the breadcrumbs
    const isLastItem = index === pathSnippets.length - 1;

    // Set the color to white if the path is /community-hub
    const color = pathname.startsWith('/community-hub') ? 'white' : 'inherit';

    return (
      <Breadcrumb.Item key={url} href={url}>
        {isLastItem ? (
          // If this is the last item, render it as plain text
          <>
            {breadcrumbIcon}
            <span
              style={{
                maxWidth: '28ch',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                color: color,
              }}
            >
              {breadcrumbName}
            </span>
          </>
        ) : (
          // Otherwise, render it as a link
          <Link to={url} style={{ color: color }}>
            {breadcrumbIcon}
            {breadcrumbName}
          </Link>
        )}
      </Breadcrumb.Item>
    );
  });

  // Add the selected thread to the breadcrumb items
  if (selectedThread) {
    breadcrumbItems.push(
      <Breadcrumb.Item key={selectedThread.id}>
        {selectedThread.name}
      </Breadcrumb.Item>,
    );
  }

  return (
    <Breadcrumb
      style={{
        margin: '16px 0 12px',
        color: pathname.startsWith('/community-hub') ? 'white' : 'inherit',
      }}
    >
      <Breadcrumb.Item href="/">
        <Link
          to="/"
          style={{
            color: pathname.startsWith('/community-hub') ? 'white' : 'inherit',
          }}
        >
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {breadcrumbItems}
    </Breadcrumb>
  );
}

export default BreadcrumbComponent;
