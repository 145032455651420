import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

function PromptInput({ value, onChange }) {
  return (
    <TextArea value={value} onChange={onChange} placeholder="Custom prompt" />
  );
}

export default PromptInput;
