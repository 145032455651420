// src/components/ScraperSider.js
import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Layout, Menu, Affix, Button, Modal, Typography, Input } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MenuFoldOutlined,
  DownOutlined,
  KeyOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

function ScraperSider({
  scrapers,
  onSelectScraper,
  onDeleteScraper,
  onDeleteAll,
  onCreateNew,
  onScraperTitleChange,
  isDrawer,
  newScraperAlias,
  editingTitle,
  setEditingTitle,
}) {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 899px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 900px) and (max-width: 1199px)',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedScraper, setSelectedScraper] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editingScraper, setEditingScraper] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [deleteAll, setDeleteAll] = useState(false);
  const [isTitleChanged, setIsTitleChanged] = useState(false);

  const handleEditClick = (index) => {
    setEditingScraper(index);
    setEditedTitle(scrapers[index].title);
  };

  const handleTitleSubmit = (scraper) => {
    setIsTitleChanged(true);
    setEditingScraper(null);
    onScraperTitleChange(scraper, editedTitle); // Use editedTitle instead of scraper.title
    setEditedTitle('');
  };

  // Add a new function to handle the "Delete all" button click
  const handleDeleteAllClick = () => {
    setDeleteAll(true);
    setIsModalVisible(true);
  };

  const handleDeleteClick = (e, scraper) => {
    e.stopPropagation();
    setSelectedScraper(scraper);
    setIsModalVisible(true);
    setDeleteAll(false);
  };

  const handleCreateNewClick = () => {
    const newScraper = {
      title: 'New Scraper ',
      template: '',
      jobAd: '',
      prompt: '',
      generatedText: '',
    };
    setSelectedScraper(newScraper);
    if (typeof onCreateNew === 'function') {
      onCreateNew(newScraper);
    } else {
      console.error('onCreateNew is not a function');
    }
  };

  const handleSelectScraperLocal = (scraper) => {
    setSelectedScraper(scraper || null);
    onSelectScraper(scraper);
  };

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (deleteAll) {
          onDeleteAll();
        } else {
          onDeleteScraper(selectedScraper);
        }
        setIsModalVisible(false);
      }
    },
    [deleteAll, onDeleteAll, onDeleteScraper, selectedScraper],
  );

  useEffect(() => {
    if (!selectedScraper && scrapers.length > 0) {
      setSelectedScraper(scrapers[0]);
    }
  }, [scrapers, selectedScraper]);

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('keydown', handleEnterPress);
    } else {
      document.removeEventListener('keydown', handleEnterPress);
    }
    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [isModalVisible, handleEnterPress]);

  // Add a new useEffect to handle the newScraperAlias prop
  useEffect(() => {
    if (newScraperAlias) {
      const newScraper = scrapers.find(
        (scraper) => scraper.alias === newScraperAlias,
      );
      if (newScraper) {
        setSelectedScraper(newScraper);
      }
    }
  }, [newScraperAlias, scrapers]);

  return (
    <Affix offsetTop={64}>
      <Sider
        style={{
          height: '96vh',
          zIndex: 1,
          overflow: 'hidden',
          backgroundColor: '#fff',
        }}
        width={200}
        collapsed={isDrawer ? false : collapsed} // Always expanded when inside a Drawer
        collapsedWidth={48}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' || type === 'responsive') {
            setCollapsed(collapsed);
          }
        }}
      >
        {collapsed && !isDrawer ? (
          <div
            style={{
              height: '100%',
              borderRight: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop: '48px',
            }}
          >
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              style={{
                width: '180px',
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                color: 'grey',
              }}
            >
              {collapsed ? 'View all scrapers' : 'Hide scrapers'}
              <DownOutlined />
            </Button>
          </div>
        ) : (
          <Menu
            mode="inline"
            style={{
              height: '100%',
              borderRight: 0,
            }}
            selectedKeys={
              selectedScraper && selectedScraper.id
                ? [selectedScraper.id.toString()]
                : []
            }
          >
            {!isDrawer && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  type="text"
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    padding: '0 12px',
                  }}
                >
                  <MenuFoldOutlined />
                </Button>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                padding: isDrawer ? '0' : '8px 12px 16px 12px',
                borderBottom: '1px solid #f0f0f0',
                margin: '0 0 4px 0',
              }}
            >
              <Typography.Title
                level={5}
                style={{
                  margin: '0 0 16px 0',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: 'darkgrey',
                }}
              >
                <KeyOutlined style={{ marginRight: '8px' }} />
                Web Scraper
              </Typography.Title>
              <Button
                type="default"
                size="middle"
                icon={<PlusOutlined />}
                onClick={handleCreateNewClick} // Ensure this is the only place where handleCreateNewClick is called
                style={{ width: '100%' }}
              >
                New project
              </Button>
            </div>

            <Menu.ItemGroup key="g1" title="Saved projects" />
            {[...scrapers]
              .reverse()
              .filter((scraper) => !scraper.isDraft)
              .map((scraper, index) => (
                <Menu.Item
                  key={scraper.id}
                  style={{
                    padding: '6px 0px 6px 14px',
                    margin: '0px 4px',
                    fontWeight: 400,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {editingScraper === index ? (
                      <Input
                        autoFocus
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onBlur={() => handleTitleSubmit(scraper)}
                        onPressEnter={() => handleTitleSubmit(scraper)}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                        onClick={() => handleSelectScraperLocal(scraper)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            adjustItems: 'center',
                          }}
                        >
                          <div className="ellipsis">{scraper.title}</div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              type="text"
                              icon={
                                <EditOutlined
                                  style={{ fontSize: '14px', color: 'grey' }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick(index);
                              }}
                            />
                            <Button
                              type="text"
                              icon={
                                <DeleteOutlined
                                  style={{ fontSize: '14px', color: 'grey' }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(e, scraper);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Menu.Item>
              ))}
            {scrapers.length > 1 && (
              <div style={{ padding: '10px' }}>
                <Button
                  type="link"
                  onClick={handleDeleteAllClick}
                  style={{ fontSize: '12px', width: '100%' }}
                >
                  Delete all
                </Button>
              </div>
            )}
          </Menu>
        )}
        <Modal
          title="Confirmation"
          visible={isModalVisible}
          onOk={(e) => {
            e.stopPropagation();
            if (deleteAll) {
              onDeleteAll();
            } else {
              onDeleteScraper(selectedScraper);
            }
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
          okText={deleteAll ? 'Yes, delete all' : 'Yes, delete'}
          cancelText="Cancel"
          autoFocusButton="ok"
        >
          <p>
            Are you sure to delete {deleteAll ? 'all scrapers' : 'this scraper'}
            ? You won't be able to restore {deleteAll ? 'them' : 'it'} after
            confirming deletion.
          </p>
        </Modal>
      </Sider>
    </Affix>
  );
}

export default ScraperSider;
