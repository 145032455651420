import React from 'react';
import { Layout, Typography, Divider, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ContentWrapper from '../components/ContentWrapper';
import Hero from '../components/Hero';

const { Title, Paragraph } = Typography;

function AboutPage() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMediumScreen = useMediaQuery({
    query: '(min-width: 769px) and (max-width: 1024px)',
  });
  return (
    <ContentWrapper width="1200px" height="76vh">
      <Layout.Content
        style={{
          padding: isSmallScreen ? '16px' : isMediumScreen ? '24px' : '24px',
        }}
      >
        <Layout>
          <Row gutter={16}>
            <Col span={24}>
              <Title level={1}>What is Webeasy?</Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Paragraph>
                Webeasy is a platform designed to make web development easier
                and more accessible. It provides a range of tools and features
                that allow users to create, manage, and deploy their own web
                applications with ease.
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Hero
                title="Easy the Web!"
                description={
                  <>
                    <Paragraph>
                      Webeasy is designed to be user-friendly and intuitive,
                      making it suitable for both beginners and experienced
                      developers.
                    </Paragraph>
                    <ul style={{ lineHeight: '28px' }}>
                      <li>
                        Huge variety of essential webtools that you can
                        customizable and personalize
                      </li>
                      <li>
                        Extensive library of components and prebuilt templates
                        to get inspired from
                      </li>
                      <li>Affordable prices and all-on bundle deals </li>
                      <li>Super simple, modular, drag-and-drop interface</li>
                      <li>
                        A vast community of shining individuals who build for
                        each other
                      </li>
                    </ul>
                  </>
                }
                image
              />
              <Title level={2}></Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Title level={2}>Looking ahead</Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Paragraph>
                Webeasy is set to become an ultra-customizable control interface
                for micro-services that are powered by a vast community of
                entrepreneurs, learners, enthusiast, allowing for a wide range
                of applications and functionalities.
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Paragraph>
                Furthermore, users will have the ability to build their own apps
                and control interfaces within Webeasy. This will enable them to
                connect with other apps and link to a large network of
                community-run services. This means that Webeasy will not only be
                a tool for creating web applications, but also a platform for
                connecting and collaborating with other developers.
              </Paragraph>
            </Col>
          </Row>
        </Layout>
      </Layout.Content>
    </ContentWrapper>
  );
}

export default AboutPage;
